import { FC } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';

import { RootState } from 'store';
import { Checkbox } from 'ui/Checkbox';

import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';

import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { QIProgressByCompositionUnits } from 'modules/progress/general/qi/domain/entities/QIProgressByCompositionUnits';
import { QIProgressBySpecialty } from 'modules/progress/general/qi/domain/entities/QIProgressBySpecialty';

interface Props {
  progress: QIProgressByCompositionUnits | QIProgressBySpecialty;
}

function isProgressBySpe(
  progress: QIProgressByCompositionUnits | QIProgressBySpecialty
): progress is QIProgressBySpecialty {
  return (progress as QIProgressBySpecialty).speTrigram !== undefined;
}

export const SelectAllColumnCheckbox: FC<Props> = ({ progress }) => {
  const isUserFree = useIsUserFree();
  const { localCart, setLocalCart } = useAddToLocalCartContext();
  const _canAddToCart = useCanAddToCart();
  const canAddToCart = (qiNumber: number) =>
    _canAddToCart('QI', qiNumber + localCart.length);

  const globalCartQIsIds = useSelector((state: RootState) =>
    state.qiTable.selectedItemsInfos.map((qi) => qi.id)
  );

  const specialtyQIsIds = isProgressBySpe(progress)
    ? progress.sessions.flatMap((qi) => qi.questions).map((qi) => qi.id)
    : Object.keys(progress.compositionUnits)
        .map((compositionUnit) =>
          // @ts-ignore
          progress.compositionUnits[parseInt(compositionUnit)].map(
            (group) => group.id
          )
        )
        .flat();

  const areAllQIsSelected = specialtyQIsIds.every(
    (qiId) => localCart.includes(qiId) || globalCartQIsIds.includes(qiId)
  );

  const areAllQIsInGlobalCart = specialtyQIsIds.every((qiId) =>
    globalCartQIsIds.includes(qiId)
  );

  const handleCheckboxClick = () => {
    if (areAllQIsSelected) {
      deselectAll();
    } else {
      addNotAlreadyInCart();
    }
  };

  const deselectAll = () => {
    const cartWithoutSpeQIs = localCart.filter(
      (qiId) => !specialtyQIsIds.includes(qiId)
    );
    setLocalCart(cartWithoutSpeQIs);
  };

  const addNotAlreadyInCart = () => {
    const qisNotAlreadyInLocalCart = specialtyQIsIds.filter(
      (id) => !localCart.includes(id)
    );
    const qisNotInAnyCart = qisNotAlreadyInLocalCart.filter(
      (qiId) => !globalCartQIsIds.includes(qiId)
    );

    if (canAddToCart(qisNotInAnyCart.length)) {
      setLocalCart([...localCart, ...qisNotInAnyCart]);
    }
  };

  return (
    <Tippy content="Fonctionnalité premium" disabled={!isUserFree}>
      <div>
        <Checkbox
          checked={areAllQIsSelected}
          onClick={handleCheckboxClick}
          // onChange={() => {}}
          disabled={areAllQIsInGlobalCart || isUserFree}
          onChange={() => {}}
        />
      </div>
    </Tippy>
  );
};
