import { useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { Section, SubTitle } from '../CCBStatsAndGrades';
import { BlackButtonSwitch } from 'ui/switches/BlackButtonSwitch';
import { useSelector } from 'react-redux';
import {
  Dot,
  Legend,
  Legends,
  SubTitleText,
} from '../RepartitionLca/RepartitionLca';
import { RepartitionGradesLca } from './RepartitionGradesLca';
import { GradeCount } from 'http/ccb/type';

export const LCAGrades = () => {
  const theme = useTheme();
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const periodData = useMemo(() => {
    return ccbStats?.period_grades;
  }, [ccbStats]);

  const GeneralData = useMemo(() => {
    return ccbStats?.total_grades;
  }, [ccbStats]);

  const [activeDataSet, setActiveDataSet] = useState<GradeCount[]>(
    ccbStats?.period_grades
  );

  const handleDataSwitch = (clickedOption: string) => {
    if (clickedOption === 'period') {
      setActiveDataSet(periodData);
    }
    if (clickedOption === 'general') {
      setActiveDataSet(GeneralData);
    }
  };

  return (
    <BlueCard $bigPadding fullWidth maxWidth="50%">
      <Section vertical>
        <TopContainer>
          <SubTitle>
            Répartitions des notes des participants pour les LCA
          </SubTitle>
          <BlackButtonSwitch
            option1Text="Sur la période"
            onclickOption1={() => handleDataSwitch('period')}
            option2Text="Global"
            onclickOption2={() => handleDataSwitch('general')}
          />
        </TopContainer>
        <StyledLegends>
          <StyledLegend>
            <Dot fillColor={theme.colors.warning.dark} />
            <SubTitleText>LCA globale </SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={'#EF9198'} />
            <SubTitleText>LCA 1</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={theme.colors.success.main} />
            <SubTitleText>LCA 2</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={theme.colors.secondary.main} />
            <SubTitleText>Mes notes</SubTitleText>
          </StyledLegend>
        </StyledLegends>
        <RepartitionGradesLca
          data={activeDataSet}
          gradeLCA={ccbStats?.user_LCA_grade}
          gradeLCA1={ccbStats?.user_LCA1_grade}
          gradeLCA2={ccbStats?.user_LCA2_grade}
        />
      </Section>
    </BlueCard>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StyledLegends = styled(Legends)`
  justify-content: space-around;
`;

const StyledLegend = styled(Legend)`
  width: auto;
`;
