import { FC, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';
import { Knowledge } from 'types/knowledge.type';
import RankLetter from 'ui/Misc/RankLetter';
import { Rank } from 'types/knowledge.type';

interface Props {
  knowledges: Knowledge[];
}

export const MultipleKnowledgeRank: FC<Props> = ({ knowledges }) => {
  const [concentratedKnowledges, setConcentratedKnowledges] = useState<
    { rank: Rank; labels: string[] }[] | undefined
  >(undefined);

  useEffect(() => {
    const concentrateKnowledge = (
      knowledges: Knowledge[]
    ): { rank: Rank; labels: string[] }[] | undefined => {
      if (knowledges.length > 0) {
        const result = knowledges.reduce(
          (
            accumulator: { rank: Rank; labels: string[] }[],
            currentValue: Knowledge
          ) => {
            const existingEntry = accumulator.find(
              (entry: any) => entry.rank === currentValue.rank
            );

            if (existingEntry) {
              existingEntry.labels.push(currentValue.label);
            } else {
              accumulator.push({
                rank: currentValue.rank,
                labels: [currentValue.label],
              });
            }

            return accumulator;
          },
          []
        );

        return result;
      } else {
        return undefined;
      }
    };
    const formatedKnowledge = concentrateKnowledge(knowledges);
    setConcentratedKnowledges(formatedKnowledge);
  }, [knowledges]);

  const createTooltip = (labels: string[]) => {
    if (labels.length > 1) {
      return (
        <ul>
          {labels.map((label: string) => (
            <li key={label}>{label}</li>
          ))}
        </ul>
      );
    } else if (labels.length === 1) {
      return labels[0];
    }
  };

  if (!knowledges) {
    return <></>;
  }

  return (
    <Container>
      {concentratedKnowledges?.map((concentratedKnowledge, index) => (
        <Tippy
          content={createTooltip(concentratedKnowledge?.labels)}
          arrow={false}
          placement="right"
          key={`concentratedKnowledge${index}`}
        >
          <RankLetter letter={concentratedKnowledge.rank}>
            {concentratedKnowledge.rank}
          </RankLetter>
        </Tippy>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;
