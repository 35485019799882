export const VIEWED_POPUP_AT = 'VIEWED_POPUP_AT';
// POPUP is managed by backend vs MODAL
export const POPUP = 'POPUP';
export const MODAL = 'MODAL';
export const NEWSLETTER = 'NEWSLETTER';

type PersistedKey =
  | typeof VIEWED_POPUP_AT
  | typeof POPUP
  | typeof MODAL
  | typeof NEWSLETTER;

function serialize<T>(value: T): string {
  try {
    return JSON.stringify(value);
  } catch (e) {
    throw Error(String(e));
  }
}

export function deserialize<T>(value: string): T {
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    throw Error(String(e));
  }
}

export function persist<T>(key: PersistedKey, value: T): void {
  const serializedValue = serialize(value);
  localStorage.setItem(key, serializedValue);
}

export function restore<T>(key: PersistedKey, defaultValue: T): T {
  const item: string | null = localStorage.getItem(key);

  if (item && item !== 'undefined' && item !== 'null') {
    return deserialize<T>(item);
  }

  return defaultValue;
}

export function remove(key: PersistedKey): void {
  localStorage.removeItem(key);
}
