import { postAPI } from 'auth';
import { TaskRequestType } from 'types/task.type';

export const submitTask = async (taskId: number) => {
  // console.log('/training/submit_task/, taskId', taskId)

  const response = await postAPI<void, TaskRequestType>(
    '/training/submit_task',
    { task_id: taskId }
  );
  // console.log('/training/submit_task/, response', response.data)
  return response.data;
};
