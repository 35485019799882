import { FC } from 'react';
import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';

import {
  STATEMENT_ID,
  useClickedQuestionContext,
} from '../clickedQuestionContext';
import { RecapQuestionsList } from './RecapQuestionsList';
import {
  formatGradePointsCoeff,
  formatGradeOutOfTwenty,
  GradeData,
} from '../components/CorrectionGroupHeader/points-formatter';
import { CorrectionGroup } from 'types/correction.type';

interface Props {
  groupData: CorrectionGroup;
  isOpen: boolean;
  toggleOpen: () => void;
}

export const CorrectionGroupRecap: FC<Props> = ({
  groupData,
  isOpen,
  toggleOpen,
}) => {
  const { setClickedQuestion, isPointPerQuestion } =
    useClickedQuestionContext();
  const userGradeData: GradeData = {
    grade: groupData.grade,
    totalCoeff: groupData.total_coefficient ?? 1,
  };
  const handleHeaderClick = () => {
    toggleOpen();
  };

  return (
    <div>
      <Header onClick={handleHeaderClick}>
        <UpperRow>
          <HeaderTitle>
            <h5>
              {groupData.custom_qi
                ? `Questions isolées`
                : `${groupData.group?.collection} - ` +
                  (groupData.group?.composition_unit
                    ? `UC${groupData.group?.composition_unit} `
                    : '') +
                  `${groupData.group?.type}${groupData.group?.num}`}
            </h5>
            <GradeContainer>
              <h5>
                {isPointPerQuestion
                  ? formatGradePointsCoeff(userGradeData)
                  : formatGradeOutOfTwenty(userGradeData)}
              </h5>
            </GradeContainer>
          </HeaderTitle>
        </UpperRow>
        <HeaderIconsContainer>
          {groupData.group?.specialty1 && (
            <HeaderSpeIcon trigram={groupData.group?.specialty1} />
          )}
          {groupData.group?.specialty2 && (
            <HeaderSpeIcon trigram={groupData.group?.specialty2} />
          )}
        </HeaderIconsContainer>
      </Header>

      {isOpen && (
        <>
          {!groupData.custom_qi && (
            <Statement onClick={() => setClickedQuestion(STATEMENT_ID)}>
              Énoncé
            </Statement>
          )}
          <RecapQuestionsList
            questions={groupData.correction_questions}
            isQI={groupData.custom_qi}
          />
        </>
      )}
    </div>
  );
};

const Header = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 16px 12px 16px;

  @media ${device.largeTable} {
    padding: 8px 16px;
  }

  z-index: 1;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Statement = styled.div`
  ${({ theme }) => theme.typography.textBody2}
  margin-top: 12px;
  border-radius: 8px;

  padding: 8px 4px;
  @media ${device.largeTable} {
    padding: 8px 14px;
  }

  cursor: pointer;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colors.primary.light};
  }
`;
const HeaderIconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const HeaderSpeIcon = styled(SpecialtyIcon)`
  --size: 20px;
  height: var(--size);
  width: var(--size);
`;

const UpperRow = styled.div``;

const GradeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
