import { Options } from 'types/options.type';
const typeFilterOptions: Options = [
  {
    label: 'QRPL',
    value: 'QRPL',
  },
  {
    label: 'QROC',
    value: 'QROC',
  },
  {
    label: 'QRM',
    value: 'QRM',
  },
  {
    label: 'QRU',
    value: 'QRU',
  },
  {
    label: 'QRP',
    value: 'QRP',
  },
  {
    label: 'TCS',
    value: 'TCS',
  },
  {
    label: 'Zone à pointer',
    value: 'QZP',
  },
];

export default typeFilterOptions;
