import { FC } from 'react';
import styled from 'styled-components/macro';
import {
  Menu,
  MenuHeader,
  MenuRadioGroup,
  MenuDivider,
  SubMenu,
  MenuItem,
  FocusableItem,
} from '@szhsin/react-menu';
import { renderRadios } from 'helpers/ReactMenu/renderRadio';
import { useTableFilters } from 'hooks/useTableFilter';
import { useLessonsQueryParamsContext } from 'pages/lessons/contexts/LessonsQueryParamsContext';
import { ColumnHeaderWrapper } from 'pages/selection/components/ColumnHeaderWrapper';
import { FilterInput } from 'pages/selection/components/FilterInput';
import { sortOptions } from 'pages/selection/constants';
import { useGetItemsListAsOptions } from 'modules/items/hooks/useGetItemsListAsOptions';

const ItemFilterHeader: FC = () => {
  const {
    itemIds: selectedItemIds,
    setItemIds: setSelectedItemIds,
    sortOrder,
    setSortOrder,
  } = useLessonsQueryParamsContext();

  const { data: itemsList } = useGetItemsListAsOptions();

  const {
    searchInput,
    setSearchInput,
    filteredOptions,
    toggleFilter,
    isFilterSelected,
    isFiltering,
  } = useTableFilters(itemsList ?? [], selectedItemIds, setSelectedItemIds);

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isFiltering={isFiltering} sortOrder={sortOrder}>
          <h2>Item</h2>
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuHeader>Trier</MenuHeader>
      <MenuRadioGroup
        value={sortOrder}
        onRadioChange={(e) => {
          setSortOrder(e.value);
        }}
      >
        {renderRadios([
          { value: sortOptions.ascending, label: 'Croissant' },
          { value: sortOptions.descending, label: 'Décroissant' },
        ])}
      </MenuRadioGroup>
      <MenuDivider />
      <MenuHeader>Filtre</MenuHeader>
      <SubMenu
        label="Filtrer par"
        menuStyles={{ maxHeight: 500, overflow: 'auto', paddingTop: 0 }}
      >
        <StickyFocusableItem>
          {({ ref }) => (
            <FullWidthInput
              ref={ref}
              type="text"
              placeholder="Filtrer"
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
            />
          )}
        </StickyFocusableItem>
        {filteredOptions.map((option) => (
          <MenuItemStyled
            type="checkbox"
            value={option.value}
            key={option.value}
            checked={isFilterSelected(option.value)}
            onClick={(e) => {
              e.keepOpen = true;
              toggleFilter(option.value);
            }}
          >
            {option.label}
          </MenuItemStyled>
        ))}
      </SubMenu>
    </Menu>
  );
};

const StickyFocusableItem = styled(FocusableItem)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const FullWidthInput = styled(FilterInput)`
  width: 100%;
`;

const MenuItemStyled = styled(MenuItem)`
  max-width: 600px;
`;

export default ItemFilterHeader;
