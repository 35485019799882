import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOptions } from 'pages/selection/constants';
import { SortByPayload, SortColumns } from 'store/types';

export interface DecksTableState {
  sortOrder?: SortOptions;
  sortColumn?: SortColumns;
  pageSize: number;
  page: number;
}

const initialState: DecksTableState = {
  pageSize: 20,
  page: 0,
  sortOrder: 'ASCENDING',
  sortColumn: 'label',
};

export const decksTableSlice = createSlice({
  name: 'decksTable',
  initialState,
  reducers: {
    sortBy: (state, action: PayloadAction<SortByPayload>) => {
      state.sortOrder = action.payload.order;
      state.sortColumn = action.payload.sortColumn;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { sortBy, setPageSize, setPage, reset } = decksTableSlice.actions;

export const decksTableReducer = decksTableSlice.reducer;
