import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  FormatedCCBSession,
  CCBTask,
  CCBStats,
  CCBRanking,
  CCBStatus,
  CurrentCCB,
  CCBCorrection,
  Statstabs,
  Collection,
  Options,
} from 'http/ccb/type';
import { isPastDate } from 'helpers/utils';
import {
  getCurrentCCB,
  getCCBCorrection,
  getCCBstatus,
  getCCBStats,
  getCCBRanking,
} from 'http/ccb/api';

interface FetchSessionsParams {
  trainingDuration: string;
  ucDuration: number;
}

interface FetchStatsParams {
  promotion: string | null;
}

interface FetchSessionsPayload {
  collection: Collection;
  permission: 'free' | 'private' | 'premium';
  sessions: FormatedCCBSession[];
  CCB_start_date: string;
  CCB_end_date: string;
  ccbStatsAvailable: boolean;
  isCCBActivatedFree: boolean;
  promotions: Options[];
  private_users: number[];
}

export const fetchFormatedSessionsStatus = createAsyncThunk(
  'ccb/fetchFormatedSessionsStatus',
  async ({ trainingDuration, ucDuration }: FetchSessionsParams) => {
    const currentCCB: CurrentCCB | undefined = await getCurrentCCB();
    const ccbStatus: CCBStatus | undefined =
      currentCCB && (await getCCBstatus(currentCCB.id));
    const ccbStatsAvailable = currentCCB?.correction ?? false;
    if (ccbStatus && currentCCB) {
      const sessions = (
        Object.keys(ccbStatus) as Array<keyof typeof ccbStatus>
      ).map((key) => {
        const taskStatus = ccbStatus[key];
        const taskId =
          typeof taskStatus === 'object' && taskStatus !== null
            ? taskStatus.task_id
            : taskStatus;

        return {
          ccbId: currentCCB.id,
          taskId: taskId as number | null,
          finished:
            taskStatus === null
              ? false
              : (taskStatus as { task_id: number; finished: boolean }).finished,
          composition_unit: key,
          start_date: currentCCB.start_date,
          end_date: currentCCB.end_date,
          duration: `${ucDuration / 60}h`,
          hasBeenStarted: taskStatus !== null,
          timeRange: trainingDuration === 'extraTime' ? 'Oui' : 'Non',
        };
      });

      const isCCBActivatedFree = isPastDate(currentCCB.start_date);

      return {
        collection: currentCCB.collection,
        permission: currentCCB.permission,
        sessions,
        CCB_start_date: currentCCB.start_date,
        CCB_end_date: currentCCB.end_date,
        ccbStatsAvailable,
        isCCBActivatedFree,
        promotions: currentCCB.promotions,
        private_users: currentCCB.private_users,
      };
    }
    return {
      collection: {} as Collection,
      permission: 'private' as 'free' | 'private' | 'premium',
      sessions: [],
      CCB_start_date: '',
      CCB_end_date: '',
      ccbStatsAvailable: false,
      isCCBActivatedFree: false,
      promotions: [],
      private_users: [],
    };
  }
);

export const fetchFormatedCCBStats = createAsyncThunk<
  {
    ccbStats: CCBStats | undefined;
    ccbRanking: CCBRanking | undefined;
    ccbCorrection: CCBCorrection | undefined;
  },
  FetchStatsParams
>('ccb/fetchCCBStats', async ({ promotion }: FetchStatsParams) => {
  const currentCCB: CurrentCCB | undefined = await getCurrentCCB();

  const ccbStats: CCBStats | undefined =
    currentCCB && (await getCCBStats(currentCCB.id, promotion));

  const ccbRanking: CCBRanking | undefined =
    currentCCB && (await getCCBRanking(currentCCB.id, promotion));

  const ccbCorrection: CCBCorrection | undefined =
    currentCCB && (await getCCBCorrection(currentCCB.id, promotion));
  return { ccbStats, ccbRanking, ccbCorrection };
});

export interface CCBState {
  collection: Collection;
  permission: 'private' | 'free' | 'premium';
  sessions: FormatedCCBSession[];
  sessionsLeftToDo: FormatedCCBSession[];
  nextTask: CCBTask | undefined;
  lastTask: CCBTask | undefined;
  alldone: boolean;
  screenToDisplay: string;
  hasUpdatedPromotion: boolean;
  CCB_start_date: string;
  CCB_end_date: string;
  ccbStats: CCBStats | undefined;
  ccbRanking: CCBRanking | undefined;
  ccbCorrection: CCBCorrection | undefined;
  isCCBActivatedFree: boolean;
  ccbStatsAvailable: boolean;
  statsActivePromotion: Options;
  statsActiveTab: Statstabs;
  promotions: Options[];
  private_users: number[];
  promotions_updated_at: string | undefined;
  specialities: string[];
  grades: string[];
  periodRank: string[];
  generalRank: string[];
}

const initialState: CCBState = {
  collection: {} as Collection,
  permission: 'private',
  sessions: [],
  sessionsLeftToDo: [],
  nextTask: undefined,
  lastTask: undefined,
  alldone: false,
  screenToDisplay: '',
  hasUpdatedPromotion: false,
  CCB_start_date: '',
  CCB_end_date: '',
  ccbStats: undefined,
  ccbRanking: undefined,
  ccbCorrection: undefined,
  isCCBActivatedFree: false,
  ccbStatsAvailable: false,
  statsActivePromotion: { name: 'Toutes les promos', code: null },
  statsActiveTab: 'Classement',
  promotions: [],
  private_users: [],
  promotions_updated_at: undefined,
  specialities: [],
  grades: [],
  periodRank: [],
  generalRank: [],
};

export const ccbSlice = createSlice({
  name: 'ccb',
  initialState,
  reducers: {
    updateNextTask(state, action: PayloadAction<CCBTask | undefined>) {
      state.nextTask = action.payload;
    },
    updateLastTask(state, action: PayloadAction<CCBTask | undefined>) {
      state.lastTask = action.payload;
    },
    updateAllDone(state, action: PayloadAction<boolean>) {
      state.alldone = action.payload;
    },
    updateScreenToDisplay(state, action: PayloadAction<string>) {
      state.screenToDisplay = action.payload;
    },
    updateSessions(state, action: PayloadAction<FormatedCCBSession[]>) {
      state.sessions = action.payload;
    },
    updateSessionsLeftToDo(state, action: PayloadAction<FormatedCCBSession[]>) {
      state.sessionsLeftToDo = action.payload.filter((task) => !task.finished);
    },
    updateHasUpdatedPromotion(state, action: PayloadAction<string>) {
      state.hasUpdatedPromotion = true;
      state.promotions_updated_at = action.payload;
    },
    resetHasUpdatedPromotion(state) {
      state.hasUpdatedPromotion = false;
      state.promotions_updated_at = '';
    },
    updateStatsActivePromotion(state, action: PayloadAction<Options>) {
      state.statsActivePromotion = action.payload;
    },
    updateStatsActiveTab(state, action: PayloadAction<Statstabs>) {
      state.statsActiveTab = action.payload;
    },
    updateSpecialitiesFilter: (state, action: PayloadAction<string[]>) => {
      state.specialities = action.payload;
    },
    updateGradeFilter: (state, action: PayloadAction<string[]>) => {
      state.grades = action.payload;
    },
    updatePeriodRankFilter: (state, action: PayloadAction<string[]>) => {
      state.periodRank = action.payload;
    },
    updateGeneralRankFilter: (state, action: PayloadAction<string[]>) => {
      state.generalRank = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchFormatedSessionsStatus.fulfilled,
      (state, action: PayloadAction<FetchSessionsPayload>) => {
        const {
          collection,
          permission,
          sessions,
          CCB_start_date,
          CCB_end_date,
          ccbStatsAvailable,
          isCCBActivatedFree,
          promotions,
          private_users,
        } = action.payload;

        state.collection = collection;
        state.permission = permission;
        state.sessions = sessions;
        state.sessionsLeftToDo = sessions.filter((task) => !task.finished);
        state.CCB_start_date = CCB_start_date;
        state.CCB_end_date = CCB_end_date;
        state.isCCBActivatedFree = isCCBActivatedFree;
        state.ccbStatsAvailable = ccbStatsAvailable;
        state.promotions = promotions;
        state.private_users = private_users;
        state.alldone =
          state.sessions &&
          state.sessions.filter(
            (session: FormatedCCBSession) => session.finished
          ).length === state.sessions.length &&
          state.sessionsLeftToDo.length === 0;
      }
    );
    builder.addCase(
      fetchFormatedCCBStats.fulfilled,
      (
        state,
        action: PayloadAction<{
          ccbStats: CCBStats | undefined;
          ccbRanking: CCBRanking | undefined;
          ccbCorrection: CCBCorrection | undefined;
        }>
      ) => {
        state.ccbStats = action.payload.ccbStats;
        state.ccbRanking = action.payload.ccbRanking;
        state.ccbCorrection = action.payload.ccbCorrection;
      }
    );
  },
});

export const {
  updateNextTask,
  updateLastTask,
  updateAllDone,
  updateSessions,
  updateScreenToDisplay,
  updateSessionsLeftToDo,
  updateStatsActivePromotion,
  updateHasUpdatedPromotion,
  resetHasUpdatedPromotion,
  updateStatsActiveTab,
  updateSpecialitiesFilter,
  updateGradeFilter,
  updatePeriodRankFilter,
  updateGeneralRankFilter,
} = ccbSlice.actions;

export const ccbReducer = ccbSlice.reducer;
