import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const Plus: FC<SVGNativeProps> = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="9.38086"
        y1="1.23804"
        x2="9.38086"
        y2="15.9999"
        stroke="#7F8A95"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1.76196"
        y1="8.38098"
        x2="16.5239"
        y2="8.38098"
        stroke="#7F8A95"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
