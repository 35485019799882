import { useGetPieChart } from 'pages/home/components/FlashCardsStats/useGetPieChart';
import { PieChart as Chart, Pie, ResponsiveContainer, Cell } from 'recharts';
import styled, { useTheme } from 'styled-components';

export const FlashPieChart = () => {
  const theme = useTheme();

  const { isLoading, pieChartData, totalNumOfQuestions } = useGetPieChart();

  const customLabel = (props: any) => {
    if (props.payload.payload.value === 0) {
      return null;
    }

    const leftCoef = props.direction === 'left' ? -1 : 1;

    const RADIAN = Math.PI / 180;
    const radius =
      12 + props.innerRadius + (props.outerRadius - props.innerRadius);
    var x = props.cx + radius * Math.cos(-props.midAngle * RADIAN) * leftCoef;
    x = x < props.cx ? x - 25 : x + 25;

    const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

    const textA = x > props.cx ? 'start' : 'end';

    return (
      <text
        x={x}
        y={y}
        fill={theme.colors.primary.dark}
        textAnchor={textA}
        dominantBaseline="central"
        fontSize="16px"
      >
        {totalNumOfQuestions &&
          `${props.value}/${totalNumOfQuestions} (${(
            (props.value / totalNumOfQuestions) *
            100
          ).toFixed(0)}%)`}
      </text>
    );
  };

  const customLabelLine = (props: any) => {
    if (props.payload.payload.value === 0) {
      return <></>;
    }

    const isLeft = props.direction === 'left';

    const RADIAN = Math.PI / 180;
    const radius = props.outerRadius - 8;
    const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
    const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);
    var x2 =
      x +
      18.5 * Math.cos(-props.midAngle * RADIAN) +
      2 * Math.sin(props.midAngle * RADIAN) -
      1.5;
    const y2 =
      y +
      (18.5 * Math.sin(-props.midAngle * RADIAN) +
        2 * Math.cos(props.midAngle * RADIAN) -
        2);

    return (
      <g>
        <g
          fill={props.payload.fill}
          transform={`rotate(${-props.midAngle})`}
          transform-origin={`${x} ${y}`}
        >
          <rect x={x} y={y} width="20" height="4" rx="3" ry="3" />
        </g>
        <g
          fill={props.payload.fill}
          transform={x2 < props.cx || isLeft ? 'rotate(180)' : undefined}
          transform-origin={`${x2 + 1.5} ${y2 + 2}`}
        >
          <rect x={x2} y={y2} width="20" height="4" rx="3" ry="3" />
        </g>
      </g>
    );
  };

  return isLoading ? (
    <div style={{ width: '40vw', height: '70vh' }}></div>
  ) : (
    <Container>
      <Chart>
        <Pie
          data={pieChartData}
          dataKey="value"
          outerRadius={160}
          startAngle={90}
          endAngle={90 + 360}
          label={customLabel}
          labelLine={customLabelLine}
        >
          <Cell key="cell-1" fill={theme.colors.primary.main} stroke="none" />
          <Cell key="cell-2" fill={theme.colors.primary.dark} stroke="none" />
          <Cell
            key="cell-3"
            fill={theme.colors.secondary.dark}
            stroke="none"
            direction="left"
          />
        </Pie>
      </Chart>
    </Container>
  );
};

const Container = styled(ResponsiveContainer)`
  flex: 5;
`;
