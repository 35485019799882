import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectionQIResult } from 'http/selection-qi/types';
import { SortOptions } from 'pages/selection/constants';
import { areItemInfosEqual } from 'pages/selection/TableHeaders/utils';
import { SortColumns, SortByPayload } from 'store/types';

export interface QITableState {
  sortOrder?: SortOptions;
  sortColumn?: SortColumns;
  pageSize: number;
  page: number;
  selectedItemsInfos: SelectionQIResult[];
  openedRowId?: number;
}

const initialState: QITableState = {
  pageSize: 20,
  page: 0,
  selectedItemsInfos: [],
  sortOrder: 'DESCENDING',
  sortColumn: 'collection',
};

export const qiTableSlice = createSlice({
  name: 'qiTable',
  initialState,
  reducers: {
    sortBy: (state, action: PayloadAction<SortByPayload>) => {
      state.sortOrder = action.payload.order;
      state.sortColumn = action.payload.sortColumn;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    toggleItemSelection: (state, action: PayloadAction<SelectionQIResult>) => {
      const itemInfos = action.payload;
      const isItemAlreadySelected = state.selectedItemsInfos.some(
        (selectedItem) => areItemInfosEqual(selectedItem, itemInfos)
      );

      if (isItemAlreadySelected) {
        const selectedItemsWithoutToggledItem = state.selectedItemsInfos.filter(
          (item) => !areItemInfosEqual(item, itemInfos)
        );
        state.selectedItemsInfos = selectedItemsWithoutToggledItem;
      } else {
        state.selectedItemsInfos.push(itemInfos);
      }
    },
    updateItemSelection: (
      state,
      action: PayloadAction<SelectionQIResult[]>
    ) => {
      const itemInfos = action.payload;
      state.selectedItemsInfos = itemInfos;
    },
    openRow: (state, action: PayloadAction<number | undefined>) => {
      state.openedRowId = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  sortBy,
  setPageSize,
  toggleItemSelection,
  updateItemSelection,
  openRow,
  setPage,
  reset,
} = qiTableSlice.actions;

export const qiTableReducer = qiTableSlice.reducer;
