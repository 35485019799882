import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { QI } from 'modules/progress/detailed/shared/entities/QI';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { GradeData } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { SmallDownArrow } from 'ui/icons/SmallDownArrow';
import { CheckboxCell } from './CheckboxCell';
import { SubRow } from './SubRow';
import ItemFromId from 'components/Item/ItemFromId';

interface Props {
  question: QI;
}

export const Row: FC<Props> = ({ question }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasBeenDone = question.lastGrade !== undefined;

  const toggleOpen = () =>
    hasBeenDone ? setIsOpen((oldVal) => !oldVal) : null;

  const gradeData: GradeData = {
    grade: question.lastGrade ?? null,
    totalCoeff: question.gradeDenom,
  };

  const cellProps = {
    onClick: toggleOpen,
    hasBeenDone,
  };

  return (
    <>
      <Cell {...cellProps}>
        QI n°
        {question.num}
      </Cell>
      <Cell {...cellProps}>
        <PremiumLockedRessource
          ressourceName="Spécialité"
          isFree={question.isFree}
        >
          <SpeContainer>
            {question.specialtyTrigram && (
              <>
                <SpecialtyIcon trigram={question.specialtyTrigram} />{' '}
                {question.specialtyTrigram}
              </>
            )}
          </SpeContainer>
        </PremiumLockedRessource>
      </Cell>
      <Cell {...cellProps}>
        <PremiumLockedRessource ressourceName="Item" isFree={question.isFree}>
          {question.item && <ItemFromId itemId={question.item} />}
        </PremiumLockedRessource>
      </Cell>
      <Cell {...cellProps}>{formatGradeOutOfTwenty(gradeData)}</Cell>
      <Cell {...cellProps}>
        <CheckboxCell question={question} />
      </Cell>
      <Cell {...cellProps}>{hasBeenDone && <Arrow isOpen={isOpen} />}</Cell>

      {isOpen && <SubRow history={question.previousTrainings} />}
    </>
  );
};

const Cell = styled.div<{ hasBeenDone: boolean }>`
  ${({ theme }) => theme.typography.h4}

  background-color: ${({ theme, hasBeenDone }) =>
    hasBeenDone ? theme.colors.primary.main : theme.colors.background.paper};
  display: grid;
  place-items: center;

  ${({ hasBeenDone }) =>
    hasBeenDone &&
    css`
      cursor: pointer;
    `}
`;

const SpeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Arrow = styled(({ isOpen, ...props }: { isOpen: boolean }) => (
  <SmallDownArrow {...props} />
))`
  --size: 8px;
  height: var(--size);
  width: var(--size);

  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
