import { useGetSessionListRecap } from 'http/selection/session-list/connector';
import { useGetSpecialitiesListSorted } from 'http/selection/specialities-list/connectors';
import { useGetSessionRecap } from 'modules/recap/hooks/useGetSessionrecap';
import { useGetSpecialtyRecap } from 'modules/recap/hooks/useGetSpecialtyRecap';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SessionDropdown, SessionOption } from './SessionDropdown';
import { SessionRecap } from './SessionRecap';
import { SpecialtyDropdown, SpecialtyOption } from './SpecialtyDropdown';
import { SpecialtyRecap } from './SpecialtyRecap';
import { ToggleSwitch } from './ToggleSwitch';

interface Props {}

export const TrainingStats: FC<Props> = (props) => {
  const [isSpe, setIsSpe] = useState<boolean>(false);

  const sessions = useGetSessionListRecap();
  const [selectedSession, setSelectedSession] = useState<SessionOption>();

  const specialties = useGetSpecialitiesListSorted();
  const [selectedSpecialty, setSelectedSpecialty] = useState<SpecialtyOption>();

  const { data: sessionRecap } = useGetSessionRecap(
    selectedSession
      ? {
          collection: selectedSession.value.collection,
          // completed : selectedSession.value.completed
        }
      : undefined
  );

  const { data: specialtyRecap } = useGetSpecialtyRecap(
    selectedSpecialty
      ? {
          specialty: selectedSpecialty.value,
        }
      : undefined
  );

  useEffect(() => {
    if (sessions.selectSessionList) {
      setSelectedSession(sessions.selectSessionList[0]);
    }
  }, [sessions.selectSessionList]);

  useEffect(() => {
    if (specialties.data?.options) {
      setSelectedSpecialty(specialties.data.options[0]);
    }
  }, [specialties.data?.options]);

  return (
    <Container>
      <HeaderContainer>
        {!isSpe ? (
          <StyledSessionDropdown
            options={sessions.selectSessionList}
            value={selectedSession}
            onChange={(newValue) =>
              newValue ? setSelectedSession(newValue) : null
            }
          />
        ) : (
          <StyledSpecialtyDropdown
            options={specialties.data?.options}
            value={selectedSpecialty}
            onChange={(newValue) =>
              newValue ? setSelectedSpecialty(newValue) : null
            }
          />
        )}
        <SwitchContainer>
          <SessionText>Session</SessionText>
          <StyledToggleSwitch
            checked={isSpe}
            onChange={() => setIsSpe(!isSpe)}
          />
          <SpeText>Spe</SpeText>
        </SwitchContainer>
      </HeaderContainer>
      {!isSpe ? (
        <SessionRecap
          sessionRecap={sessionRecap}
          selectedSession={selectedSession ? selectedSession.label : ''}
        />
      ) : (
        <SpecialtyRecap specialtyRecap={specialtyRecap} />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  width: 640px;
  height: 725px;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledSessionDropdown = styled(SessionDropdown)`
  width: 200px;
`;

const StyledSpecialtyDropdown = styled(SpecialtyDropdown)`
  width: 200px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SessionText = styled.label`
  ${({ theme }) => theme.typography.small}
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  grid-column: 2;
  grid-row: 1;
`;

const SpeText = styled.label`
  ${({ theme }) => theme.typography.small}
`;
