import { device } from 'helpers/css/responsive';
import { StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components/macro';

/** Type to specify if we can have multiple inputs in the select.
The type 'StylesConfig' needs it to change the type of the function 'selectStyles' that allows styling the react-select. */
export type IsMulti = false;

/**Type of the options passed to the react-select */
export interface Option {
  label: string | undefined;
  value: string | undefined;
}

export const getDropdownStyle = (theme: DefaultTheme) => {
  const selectStyles: StylesConfig<Option, IsMulti> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }
      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        border:
          '2px solid ' +
          (state.selectProps.className === 'isValid'
            ? theme.colors.primary.dark
            : theme.colors.error.main),
        boxShadow: state.isFocused ? 'none' : 'none',
        borderRadius: theme.borderRadius.cta.mobile,
        '&:hover': {},
        paddingLeft: '5px',
        backgroundColor: 'transparent',
      };
      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
        style.paddingLeft = '12px';
        style.borderRadius = theme.borderRadius.cta.desktop;
      }

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        transition: 'all .2s ease',
        marginRight: '12px',
        marginLeft: '8px',
      };
      if (state.selectProps.menuIsOpen) {
        style.transform = 'rotate(180deg)';
      }
      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
        style.marginRight = '20px';
        style.marginLeft = '15px';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        padding: '8px',
        border:
          '2px solid ' +
          (state.selectProps.className === 'isValid'
            ? theme.colors.primary.dark
            : theme.colors.error.main),
        backgroundColor: theme.colors.background.paper,
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
  };

  return selectStyles;
};

export const getSearchBarDropdownStyle = (theme: DefaultTheme) => {
  const selectStyles: StylesConfig<Option> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }
      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        border: '2px solid ' + theme.colors.primary.lighter,
        boxShadow: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        '&:hover': {},
        paddingLeft: '5px',
        backgroundColor: 'transparent',
      };
      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
        style.paddingLeft = '12px';
        style.borderRadius = theme.borderRadius.cta.desktop;
      }

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        marginRight: '12px',
        marginLeft: '8px',
      };

      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
        style.marginRight = '16px';
        style.marginLeft = '16px';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        padding: '8px',
        border: '2px solid ' + theme.colors.primary.light,
        backgroundColor: theme.colors.background.paper,
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
        backgroundColor: theme.colors.primary.lighter,
        borderRadius: '8px',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
  };

  return selectStyles;
};
