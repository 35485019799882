import { useState } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'ui/Modal';
import { device } from 'helpers/css/responsive';
import NewsletterImg from 'assets/images/Newsletter.png';
import { ModalHeader } from 'ui/Modal/ModalHeader';
import { AuthorisationCheckbox } from 'ui/AuthorisationCheckbox';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { AdModal, PopupStatus } from 'http/popup/types';
import { MS_IN_A_DAY } from 'constants/date';
import {
  persist,
  NEWSLETTER,
  deserialize,
} from 'helpers/application/localStorage';
import { NewsletterButton } from './NewsletterButton';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useAccount } from 'pages/account/useAccount';

// TODO REFACTO : have modal with localstorage logic with rest as children

export const NewsletterModal = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const { data: userOptions } = useGetUserOptions();
  const { updateUserOptions } = useAccount();

  const shouldDisplayPopup = (): boolean => {
    const modalInCookies: string =
      window.localStorage.getItem('NEWSLETTER') ?? '';

    // if user does need to be asked about newsletter
    if (userOptions?.needs_newsletter_prompt && !userOptions?.newsletter) {
      // if modal not in local storage set it and display :
      if (!modalInCookies) {
        persist(NEWSLETTER, {
          title: 'Newsletter',
          status: 'active',
          content: 'want to see again',
          viewed_date: new Date().toISOString(), // Store as ISO string
        });
        return true;
      } else {
        const localStorageModal: AdModal = deserialize(modalInCookies);
        const viewedDate = new Date(localStorageModal.viewed_date); // Convert to Date object
        const modalStatus = localStorageModal.status;

        // if modal is permanent or active display once a day
        if (
          (modalStatus === PopupStatus.ACTIVE ||
            modalStatus === PopupStatus.PERMANENT) &&
          viewedDate
        ) {
          const localStorageDate = viewedDate.getTime();
          const now = new Date().getTime();
          const isOneDayPassed = now - localStorageDate > MS_IN_A_DAY;

          // if a day has passed since last seen update date
          if (isOneDayPassed) {
            persist(NEWSLETTER, {
              title: 'Newsletter',
              status: 'active',
              content: 'want to see again',
              viewed_date: new Date().toISOString(), // Store as ISO string
            });
          }

          return isOneDayPassed;

          // if we asked already return false
        } else if (
          modalStatus === PopupStatus.INACTIVE ||
          !userOptions?.needs_newsletter_prompt
        ) {
          return false;
        }
      }
      // if modal status is ACTIVE
      return true;

      //if we didnt ask and newsletter is at false
    } else return false;
  };

  const closeModal = () => {
    setIsOpen(false);
    // if checked  user don't want to be asked any more
    if (checked) {
      persist(NEWSLETTER, {
        title: 'Newsletter',
        status: PopupStatus.INACTIVE,
        content: 'want to see again',
        viewed_date: new Date().toISOString(), // Store as ISO string
      });
      updateUserOptions({ ...userOptions, needs_newsletter_prompt: false });
    }
  };

  const handleNewletterSubscription = () => {
    if (checked) {
      persist(NEWSLETTER, {
        title: 'pré-CCB',
        status: PopupStatus.INACTIVE,
        content: 'want to see again',
        viewed_date: new Date().toISOString(), // Store as ISO string
      });
    }
    updateUserOptions({
      ...userOptions,
      needs_newsletter_prompt: false,
      newsletter: true,
    });
    closeModal();
  };

  if (!shouldDisplayPopup()) return null;

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalHeader onCloseButtonClick={closeModal}>
        <Right>
          <h4>👀 Ne Manquez aucune information importante !</h4>
        </Right>
        <Left />
      </ModalHeader>
      <ModalContent>
        <ScrollableContainer>
          <Right>
            <p>
              Inscrivez-vous à notre newsletters et soyez les premiers informés
              des offres, des nouveautés et autres informations importantes
              concernant le concours des EDN.
            </p>
            <AuthorisationCheckbox
              checked={checked}
              text={'Ne plus afficher ce message'}
              type={'newsletterDisplay'}
              onChange={() => setChecked((prev) => !prev)}
            />
            <ButtonContainer>
              <NewsletterButton
                text={'Rester informé'}
                color={''}
                handleClickComplement={handleNewletterSubscription}
              />
              <ButtonBase
                onClick={closeModal}
                size="medium"
                styled={'secondary'}
                disabled={false}
                iconOnly={false}
                hasPadding={true}
              >
                {'Fermer'}
              </ButtonBase>
            </ButtonContainer>
            <LegalText>
              Les informations recueillies font l'objet d'un traitement
              informatique. Pour en savoir plus sur la collecte, nous vous
              invitons à lire notre politique de{' '}
              <a href={'https://asclepia.io/confidentiality'}>
                confidentialité de données
              </a>
              .
            </LegalText>
          </Right>
          <Left>
            <StyledImage src={NewsletterImg} alt={'Newsletter'} />
          </Left>
        </ScrollableContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    overflow: auto;
    padding-right: 4px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Left = styled.div`
  width: 40%;
  @media ${device.mobile} {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media ${device.mobile} {
    flex-direction: col;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;

const LegalText = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.small}
`;
