import { useGetGroupHistory } from 'modules/group-history/hooks/useGetGroupHistory';
import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { Pagination } from '../Pagination';
import { GroupHistoryRow } from './GroupHistoryRow';

interface Props {}

export const GroupHistoryList: FC<Props> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, _setPageSize] = useState<number>(20);
  const setPageSize = (val: number) => {
    _setPageSize(val);
    setCurrentPage(0);
  };

  const { data: paginatedGroupHistory } = useGetGroupHistory({
    shouldRetrieveFinishedGroups: true,
    pageNumber: currentPage + 1,
    pageSize: pageSize,
  });

  const groupHistory = paginatedGroupHistory?.data;

  return (
    <Container>
      <Grid>
        {groupHistory &&
          groupHistory.map((group) => (
            <GroupHistoryRow group={group} key={group.groupCorrectionId} />
          ))}
      </Grid>

      <Pagination
        {...{
          currentPage,
          setCurrentPage,
          pageSize,
          setPageSize,
          numberOfPages: paginatedGroupHistory?.numberOfPages,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 50px;
  gap: 8px;
`;
