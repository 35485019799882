import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { CorrectionQuestion } from 'types/correction.type';
import {
  getKnowledgesListHttp,
  // getKnowledgesHttp,
  getKnowledgeHttp,
} from './api';

import { CorrectionGroup } from 'types/correction.type';
import { Knowledge } from 'types/knowledge.type';
import { MS_IN_AN_HOUR } from 'constants/date';

export interface GradeData {
  grade: number | null;
  totalCoeff: number;
}

export interface Options {
  label: string;
  value: string;
}

export const QuestionTypeForAGrade = ['QRU', 'QRP', 'QROC', 'QZP'];

export const useGetKnowledge = (knowledgeId: string) => {
  return useQuery(
    ['getKnowledge', knowledgeId],
    () => getKnowledgeHttp(knowledgeId),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
};

export const useGetKnowledges = (knowledgeIds: string[]) => {
  return useQuery(
    ['getKnowledges', knowledgeIds],
    () => getKnowledgesListHttp({ knowledge: knowledgeIds }),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
};

export const useGetKnowledgesAsOptions = () => {
  return {
    data: {
      options: [
        { label: `rang A`, value: 'A' },
        { label: `rang B`, value: 'B' },
        { label: `rang C`, value: 'C' },
      ],
    },
  };
};

export const useKnowledgeInfoFromId = (knowledgeId: string) => {
  const { data: knowledge } = useGetKnowledge(knowledgeId);

  const knowledgeInfos = useMemo(() => {
    if (knowledge) {
      return {
        rank: knowledge.rank,
        label: knowledge.label,
      };
    }
  }, [knowledge]);

  return knowledgeInfos ?? { rank: undefined, label: undefined };
};

export const useGetGradesForAKnowledge = (groupData: CorrectionGroup) => {
  const knowledges = getKnowledgesFromCorrectionGroupData(groupData);
  const knowledgeInfos = useMemo(() => {
    const aRankGradeData: GradeData[] = [];
    const aRankQuestionIds: number[] = [];
    let isThereARankQuestion: boolean = false;

    if (knowledges) {
      groupData.correction_questions.forEach(
        (correction: CorrectionQuestion) => {
          const rank = knowledges.find((element: Knowledge) => {
            if (
              correction.question &&
              element &&
              element.knowledge &&
              correction.question.knowledge &&
              QuestionTypeForAGrade.includes(correction.question.type)
            ) {
              return (
                element.knowledge === correction.question.knowledge?.knowledge
              );
            }
            return null;
          })?.rank;
          if (rank === 'A') {
            aRankQuestionIds.push(correction.id);
            aRankGradeData.push({
              grade: correction.grade,
              totalCoeff: correction.question.coefficient,
            });
            isThereARankQuestion = true;
          }
        }
      );
      return {
        aRankQuestionIds,
        aRankGradeData,
        isThereARankQuestion,
      };
    }
  }, [knowledges, groupData]);

  return (
    knowledgeInfos ?? {
      aRankGradeData: undefined,
      aRankQuestionIds: undefined,
      isThereARankQuestion: false,
    }
  );
};

export const useConcentrateKnowledge = (knowledgeIds: string[]) => {
  const knowledges = useKnowledgeRawInfoFromIds(knowledgeIds);

  const knowledgeInfos = useMemo(() => {
    const result = knowledgeIds.reduce(
      (accumulator: any, currentValue: any) => {
        if (knowledges) {
          const rank = knowledges.find(
            (element) => element.knowledge === currentValue
          )?.rank;
          const label = knowledges.find(
            (element) => element.knowledge === currentValue
          )?.label;

          if (rank && label) {
            const existingEntry = accumulator.find(
              (entry: any) => entry.rank === rank
            );

            if (existingEntry) {
              existingEntry.labels.push(label);
            } else {
              accumulator.push({ rank, labels: [label] });
            }
          }
        }
        return accumulator;
      },
      []
    );

    return result;
  }, [knowledges, knowledgeIds]);
  return knowledgeInfos;
};

export const useKnowledgeRawInfoFromIds = (knowledgeIds: string[]) => {
  const knowledgeInfos = useCallback(async () => {
    const fetchKnowledgeInfo = await getKnowledgesListHttp({
      knowledge: knowledgeIds,
    });

    return Promise.all(fetchKnowledgeInfo);
  }, [knowledgeIds]);

  const { data: knowledgeData } = useQuery(
    ['getKnowledge', knowledgeIds],
    knowledgeInfos,
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
  return knowledgeData;
};

function getKnowledgesFromCorrectionGroupData(
  groupData: CorrectionGroup
): Knowledge[] {
  const knowledgeIds: Knowledge[] = [];
  groupData.correction_questions.forEach((correction) => {
    if (QuestionTypeForAGrade.includes(correction.question.type)) {
      knowledgeIds.push(correction.question.knowledge);
    }
  });
  return knowledgeIds;
}

export const useItemfromKnowledgeId = (knowledgeId: string) => {
  const { data: knowledge } = useGetKnowledge(knowledgeId);

  return knowledge?.item ?? undefined;
};

export const useItemsfromKnowledges = (knowledgeIds: string[]) => {
  const knowledges = useKnowledgeRawInfoFromIds(knowledgeIds);
  const items = knowledges?.map((knowledge) => knowledge.item);
  return items;
};
