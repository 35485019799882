import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { PieChart as Chart, Pie, ResponsiveContainer, Cell } from 'recharts';

interface Props {
  fillPercentage: number;
  className?: string;
}

export const SessionPieChart: FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Container width="100%" height={400} key={props.fillPercentage}>
      <Chart>
        <text x={'50%'} y={'50%'} textAnchor="middle" dominantBaseline="middle">
          <TitleText dy={'-1.2em'} x={'50%'} fill={theme.colors.primary.dark}>
            Global
          </TitleText>
          <PercentageText dy="1.2em" x={'50%'} fill={theme.colors.primary.dark}>
            {props.fillPercentage.toFixed(2) + '%'}
          </PercentageText>
        </text>
        <Pie
          data={[
            { name: 'Empty', value: 100 - props.fillPercentage },
            { name: 'Global', value: props.fillPercentage },
          ]}
          dataKey="value"
          cx={'50%'}
          cy={'50%'}
          innerRadius={68}
          outerRadius={132}
          startAngle={90}
          endAngle={90 + 360}
          isAnimationActive={true}
        >
          <Cell key="cell-0" fill="none" stroke="none" />
          <Cell key="cell-1" fill={theme.colors.primary.dark} />
        </Pie>
      </Chart>
    </Container>
  );
};

const Container = styled(ResponsiveContainer)``;

const TitleText = styled.tspan`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;

const PercentageText = styled.tspan`
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
`;
