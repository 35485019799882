import { useEffect, useState } from 'react';
import { getPrices } from 'http/payments/getPrices/api';
import { PriceInfos } from '../../../http/payments/getPrices/types';
import { sortDataArray, sortDataObject } from 'http/payments/getPrices/utils';
import { formatDate } from 'helpers/utils';

export const useGetPrice = () => {
  const [prices, setPrices] = useState<PriceInfos[]>([]);

  useEffect(() => {
    async function getAvailablePrices() {
      const pricesData = await getPrices();
      if (pricesData) {
        setPrices(
          pricesData.map((price) => ({
            id: price.id,
            label: price.label,
            end_subscription: formatDate(price.end_subscription),
            amount: price.amount,
            session: sortDataObject(price.session),
          }))
        );
      }
    }
    getAvailablePrices();
  }, []);

  return sortDataArray(prices);
};
