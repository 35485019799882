import { useState, useMemo } from 'react';
import { getCompassItems } from 'http/compass/api';
import { ItemKnowledgesBooks } from 'http/compass/types';
import { toast } from 'react-toastify';
import { Item } from 'types/item.type';
import { getItemsListHttp } from 'http/selection/get_item/api';
import { useQuery } from 'react-query';

export const useGetItems = () => {
  const fetchData = async () => {
    try {
      const results: Item[] = await getItemsListHttp();
      const promises = results
        .filter(
          (currentValue) =>
            currentValue.item !== 0 &&
            currentValue.item !== 368 &&
            currentValue.item !== 369
        )
        .filter(
          (currentValue) =>
            currentValue.title !== '' && currentValue.item !== null
        )
        .map((currentValue) => ({
          label: `${currentValue.item} - ${currentValue.short_title}`,
          value: `${currentValue.item}`,
        }))
        .sort((a, b) => Number(a.value) - Number(b.value));

      const itemsOptions = await Promise.all(promises);
      return itemsOptions;
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
    }
  };

  const { data: items } = useQuery('sorted_items', fetchData);

  return { items };
};

export const useGetItem = (itemId: number | undefined) => {
  const [item, setItem] = useState<ItemKnowledgesBooks>();
  useMemo(() => {
    const fetchData = async () => {
      try {
        if (!itemId) {
          const itemData = await getCompassItems(1);
          setItem(itemData);
        } else {
          const itemData = await getCompassItems(itemId);
          setItem(itemData);
        }
      } catch (error) {
        toast.error('Cet item est introuvable.');
      }
    };
    fetchData();
  }, [itemId]);
  return { item };
};
