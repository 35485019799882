import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Card as RawCard } from 'ui/Card';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';
import { Loader } from 'components/Loader/Loader';
import { requestVerifyEmail } from 'http/verify-email/api';
import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { routes } from 'routing/constants';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

export const VerifyEmail = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const goToAuthConfirmation = useGoToAuthConfirmation();

  if (token === undefined) {
    navigate(routes.HOME);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await requestVerifyEmail(token!);
        goToAuthConfirmation({
          title: 'Votre e-mail a bien été vérifié !',
          subtitle: 'Bienvenue sur Asclepia 😊',
        });
      } catch (e) {
        reportErrorToSentry(e);
        toast.error(
          "Nous n'avons pu vérifier votre email car l'URL rentrée est invalide"
        );
        navigate(routes.LOGIN);
      }
    };

    fetchData();
    // It should only run at startup so it is normal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Loader />
          <h2>Nous vérifions votre email...</h2>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Card = styled(RawCard)`
  height: 200px;
  display: grid;
  place-items: center;
  gap: 16px;
`;
