import { useMemo, FC } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';

import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';

interface GraphTippyProps {
  spe: string;
}

const GraphTippy: FC<GraphTippyProps> = ({ spe }) => {
  return (
    <Tippy
      content={spe}
      placement="top"
      interactive={true}
      delay={100}
      theme="light"
      arrow={false}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SpecialtyIcon trigram={spe} />
      </div>
    </Tippy>
  );
};

export const StatRadarChart = () => {
  const theme = useTheme();
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const formatedData = useMemo(() => {
    const formatedData = ccbStats
      ? ccbStats.user_specialty_responses.map((response: any) => {
          const total =
            response.total_pass + response.partial_pass + response.fail;
          if (total) {
            return {
              subject: response.specialty.trigram,
              succeeded: (response.total_pass * 100) / total,
              partially_succeeded:
                ((response.partial_pass + response.total_pass) * 100) / total,
              failed:
                ((response.fail + response.partial_pass + response.total_pass) *
                  100) /
                total,
              full_grade: 100,
              number_of_questions: total,
            };
          } else {
            return {
              subject: response.specialty.trigram,
              succeeded: 0,
              partially_succeeded: 0,
              failed: 0,
              full_grade: 100,
              number_of_questions: 0,
            };
          }
        })
      : [];
    return formatedData;
  }, [ccbStats]);

  return (
    <ResponsiveContainer>
      <RadarChart
        outerRadius={'80%'}
        width={730}
        height={250}
        data={formatedData}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey="subject"
          tick={(props) => {
            return (
              <foreignObject
                style={{ overflow: 'visible' }}
                width="50"
                height="26"
                x={props.x - 20}
                y={props.y - 14}
              >
                <div>
                  <GraphTippy spe={formatedData[props.index].subject}>
                    <StyledSpecialtyIcon
                      trigram={formatedData[props.index].subject}
                    />
                  </GraphTippy>
                </div>
              </foreignObject>
            );
          }}
        />
        <PolarRadiusAxis angle={30} domain={[0, 100]} />

        <Radar
          strokeWidth={2}
          dataKey="failed"
          stroke={theme.colors.error.dark}
          fill={theme.colors.error.dark}
          fillOpacity={0.3}
        />

        <Radar
          strokeWidth={2}
          dataKey="partially_succeeded"
          stroke={theme.colors.warning.dark}
          fill={theme.colors.warning.dark}
          fillOpacity={0.6}
        />
        <Radar
          strokeWidth={2}
          dataKey="succeeded"
          stroke={theme.colors.success.main}
          fill={theme.colors.success.main}
          fillOpacity={1}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default StatRadarChart;
const StyledSpecialtyIcon = styled(SpecialtyIcon)`
  z-index: 2;
`;
