import styled from 'styled-components/macro';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { FC } from 'react';
import { PrevArrow } from './PrevArrow';

export const Pagination: FC<ReactPaginateProps> = (props) => (
  <StyledPagination
    previousLabel={<PrevArrow />}
    nextLabel={<NextArrow />}
    {...props}
  />
);

const NextArrow = styled(PrevArrow)`
  transform: scaleX(-1);
`;

const StyledPagination = styled(ReactPaginate)`
  padding: 0;
  gap: 8px;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;

  li a {
    padding: 2px 4px;
    border-radius: 2px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.primary.dark};
    width: 32px;
    height: 28px;
    display: grid;
    place-items: center;

    font-size: 16px;
    line-height: 20px;
  }

  .previous a,
  .next a {
    padding: 4px;
    display: grid;
    place-items: center;
    height: 24px;
    width: 24px;
  }

  .selected a {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }

  .disabled a {
    color: #7f8a95;
  }

  li.disable,
  li.disabled a {
    cursor: default;
    text-decoration: none;
  }
`;
