import { FC } from 'react';
import { ReactSwitchProps } from 'react-switch';
import { Switch } from 'ui/Switch';
import { useTheme } from 'styled-components';

interface Props extends ReactSwitchProps {}

export const ToggleSwitch: FC<Props> = (props) => {
  const theme = useTheme();
  return <Switch offColor={theme.colors.primary.light} {...props} />;
};
