import { getAPI } from 'auth';
import { BookType } from 'types/book.type';

export const getBooksHttp = async (codes: string[]) => {
  const response = await getAPI<BookType[]>('/common/books/', {
    params: {
      code: codes,
    },
  });
  return response.data;
};
