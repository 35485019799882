import { SelectionQIResult } from 'http/selection-qi/types';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { useDispatch } from 'react-redux';
import { toggleItemSelection as toggleDPItem } from 'store/slices/dpTableSlice';
import { toggleItemSelection as toggleLCAItem } from 'store/slices/lcaTableSlice';
import { toggleItemSelection as toggleQIItem } from 'store/slices/qiTableSlice';
import { SimilarQuestion } from 'types/correction.type';

export const useToggleSimilarQuestionInCart = () => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();

  return function addSimilarQuestionToCart(question: SimilarQuestion) {
    if (!canAddToCart(question.group.type, 1)) {
      return;
    }
    let adaptedQuestion;
    switch (question.group.type) {
      case 'QI':
        adaptedQuestion = adaptSimilarQuestionToQIQuestion(question);
        dispatch(toggleQIItem(adaptedQuestion));
        break;
      case 'DP':
        dispatch(toggleDPItem(question.group));
        break;
      case 'LCA':
        dispatch(toggleLCAItem(question.group));
        break;
    }
  };
};

export const adaptSimilarQuestionToQIQuestion = (
  similarQuestion: SimilarQuestion
): SelectionQIResult => {
  return {
    id: similarQuestion.id,
    categories: similarQuestion?.categories,
    knowledge: similarQuestion.knowledge,
    item: similarQuestion?.item,
    num: similarQuestion?.num.toString(),
    composition_unit: similarQuestion?.group.composition_unit,
    number_of_questions: 1,
    collection: similarQuestion?.group.collection,
    specialty1: similarQuestion?.specialty1,
    specialty2: similarQuestion?.specialty2,
    type: 'QI',
    coefficient: 1,
    grade: similarQuestion.group.grade,
    last_training: similarQuestion.group.last_training,
    is_free: similarQuestion.group.is_free,
  };
};
