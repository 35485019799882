import { SpecialtyRecap as SpecialtyRecapType } from 'modules/recap/domain/entities/entity';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { SpePieChart } from './SpePieChart';

interface Props {
  specialtyRecap: SpecialtyRecapType | undefined;
  className?: string;
}

export const SpecialtyRecap: FC<Props> = ({ specialtyRecap, className }) => {
  const theme = useTheme();
  return (
    <StatContainer>
      <GlobalAdvencementContainer>
        <StatName>Avancement global</StatName>
        <StatNumbers>
          {specialtyRecap && specialtyRecap.denom
            ? ((specialtyRecap.numerator / specialtyRecap.denom) * 100).toFixed(
                2
              ) + '%'
            : '0%'}
        </StatNumbers>
      </GlobalAdvencementContainer>
      <ProgressBarStat
        fillingColor={theme.colors.primary.dark}
        percentage={
          specialtyRecap && specialtyRecap.denom
            ? (specialtyRecap.numerator / specialtyRecap.denom) * 100
            : 0
        }
      />
      <ChartsContainer>
        <div>
          <SpePieChart
            key={
              'rechart-pie-1' +
              (specialtyRecap
                ? specialtyRecap.doneDp + specialtyRecap.totalDp
                : '')
            }
            fillColor={theme.colors.secondary.main}
            fillPercentage={
              specialtyRecap && specialtyRecap.totalDp
                ? (specialtyRecap.doneDp / specialtyRecap.totalDp) * 100
                : 0
            }
            text1="DP"
            text2={
              specialtyRecap
                ? specialtyRecap.doneDp + '/' + specialtyRecap.totalDp
                : '0/0'
            }
          />
        </div>
        <div>
          <SpePieChart
            key={
              'rechart-pie-2' +
              (specialtyRecap
                ? specialtyRecap.doneQi + specialtyRecap.totalQi
                : '')
            }
            fillColor={theme.colors.secondary.dark}
            fillPercentage={
              specialtyRecap && specialtyRecap.totalQi
                ? (specialtyRecap.doneQi / specialtyRecap.totalQi) * 100
                : 0
            }
            text1="QI"
            text2={
              specialtyRecap
                ? specialtyRecap.doneQi + '/' + specialtyRecap.totalQi
                : '0/0'
            }
          />
        </div>
      </ChartsContainer>
    </StatContainer>
  );
};

const StatContainer = styled.div``;

const StatName = styled.span`
  ${({ theme }) => theme.typography.label}
`;

const StatNumbers = styled.span`
  ${({ theme }) => theme.typography.label}
`;

const GlobalAdvencementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 68px;
  padding-bottom: 16px;
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 60px;
`;
