import styled from 'styled-components';
import { BookInfosContainer } from './BooksInfosContainer';
import { BookSearchBar } from './BookSearchBar';
import { useGetBooks } from 'pages/compass/hooks/useGetBooks';

export const BooksInfosDisplay = () => {
  const { options, book } = useGetBooks();
  return (
    <BooksContainer>
      <LeftSide>
        <BookSearchBar options={options} />
      </LeftSide>
      <RightSide>
        <BookInfosContainer book={book} />
      </RightSide>
    </BooksContainer>
  );
};

const BooksContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const LeftSide = styled.div`
  width: 100%;
  max-width: 404px;
`;

const RightSide = styled.div`
  width: calc(100%-50px);
`;
