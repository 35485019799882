import Tippy from '@tippyjs/react';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  name: string;
  grade: string;
  median?: string;
}

export const Header: FC<Props> = ({ name, grade, median }) => {
  return (
    <Container>
      <h2>{name}</h2>
      <GradeContainer>
        {median && (
          <PremiumLockedRessource ressourceName="Médiane nationale">
            <Tippy content="Médiane nationale">
              <MedianText>{median}</MedianText>
            </Tippy>
          </PremiumLockedRessource>
        )}
        <h4>{grade && grade}</h4>
      </GradeContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const GradeContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

const MedianText = styled.h4`
  color: ${({ theme }) => theme.colors.inactive};
`;
