import { ThemeColors } from './types';

export const darkColors: ThemeColors = {
  primary: {
    light: '#18293D',
    lighter: '#18293D',
    main: '#1B2531',
    dark: '#E0E1E2',
    contrastText: '#01162D',
  },
  secondary: {
    light: '#D09795',
    main: '#A22F2B',
    dark: '#730F0B',
    contrastText: '#111111',
  },
  error: {
    light: '#DF8D70',
    main: '#E7531D',
    dark: '#C23705',
    contrastText: '#111111',
  },
  warning: {
    light: '#FFE0A7',
    main: '#F6B949',
    dark: '#ED9900',
    contrastText: '#111111',
  },
  success: {
    light: '#CDCC75',
    main: '#4C8701',
    dark: '#2D5100',
    contrastText: '#111111',
  },
  link: {
    main: '#0000EE',
  },
  background: {
    paper: '#10161D',
    main: '#10161D',
    contrastText: '#FFFFFF',
    modalOverlay: 'rgba(0,0,0,0.8)',
  },
  correctionCard: {
    successMain: '#204140',
    successComment: '#122C2B',
    failedMain: '#3F1414',
    failedComment: '#210707',
  },
  easyDispoSquares: {
    green: '#c7f0cf',
    orange: '#ffeb9d',
    red: '#fec7ce',
  },
  flashQuestions: {
    blueLightTranslucent: '#b2caf7',
    greenLightTranslucent: '#CDCC7580',
    redLightTranslucent: '#D09795',
  },
  inactive: '#7F8A95',
  compass: {
    light: '#585858',
    dark: '#373737',
    selected: '#E1E1E1',
    lightSelected: '#585858',
  },
  button: {
    primary: '#021427',
    primaryHover: '#1C3147',
    primaryDisabled: '#7F8A95',
    secondary: '',
    secondaryHover: '#E1ECFA',
    secondaryDisabled: '#7F8A95',

    activated: '#E1ECFA',
    activatedHover: '#CDE0F6',
    activatedActivated: '#A5C7EF',
    activatedDisabled: '#E1ECFA',
  },
  advertisement: '#E0E1E2',
};
