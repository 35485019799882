export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Les mois dans la méthode getMonth() sont basés sur zéro
  const year = date.getFullYear();

  // Ajoute un zéro devant le jour et le mois si nécessaire
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  return `${formattedDay}/${formattedMonth}/${year}`;
}

export function countDaysToDeadline(dateString: string): string {
  const current_date = new Date();
  const expiryDate = new Date(dateString);
  const second_diff = (current_date.getTime() - expiryDate.getTime()) / 1000;

  // if diff sup à 6 days display expiry date ELSE display number of days left
  if (second_diff < 518400) {
    return secondsToDhms(second_diff);
  } else {
    return formatDate(dateString);
  }
}

function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

//The date and time in ISO string format (e.g., "2024-06-17T09:00:00").
export function isCurrentDateTimeInRange(
  startDateTime: string,
  endDateTime: string
): boolean {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  const now = new Date();
  return now >= start && now <= end;
}

export function isPastDate(dateStr: string): boolean {
  const givenDate = new Date(dateStr);
  const currentDate = new Date();
  return givenDate < currentDate;
}

export function isAvailableForDate(dateStr: string): boolean {
  const givenDate = new Date(dateStr);
  const currentDate = new Date();
  return givenDate > currentDate;
}

export function hasSixMonthsPassed(promotionsUpdatedAt: string): boolean {
  const sixMonthsLater = new Date(promotionsUpdatedAt);

  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

  const currentDate = new Date();

  const result = currentDate >= sixMonthsLater;

  return result;
}

export function formatDateRange(
  start_date: string,
  end_date: string,
  preposition: string
) {
  const months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  // Convert the input strings to Date objects
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  // Extract day, month, and year from the start date
  const startDay = startDate.getDate();
  const startMonth = months[startDate.getMonth()];
  const startYear = startDate.getFullYear();

  // Extract day, month, and year from the end date
  const endDay = endDate.getDate();
  const endMonth = months[endDate.getMonth()];
  const endYear = endDate.getFullYear();

  // Check if both dates are in the same month and year
  if (startMonth === endMonth && startYear === endYear) {
    return preposition === 'du'
      ? `du ${startDay} au ${endDay} ${endMonth} ${startYear}`
      : `Entre ${startDay} et le ${endDay} ${endMonth} ${startYear}`;
  } else {
    return `du ${startDay} ${startMonth} au ${endDay} ${endMonth} ${endYear}`;
  }
}

export function formatTodayDate(): string {
  // Get today's date
  const today = new Date();

  // Define the options for date formatting
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  // Create a formatter for the French locale
  const formatter = new Intl.DateTimeFormat('fr-FR', options);

  // Format the date and return it with the required prefix "Au"
  return `${formatter.format(today)}`;
}

export function formatGrade(grade: number): string {
  const roundedGrade = Math.round(grade * 100) / 100;

  return roundedGrade % 1 === 0
    ? roundedGrade.toFixed(0)
    : roundedGrade.toFixed(2);
}

export function roundGrade(grade: number): number {
  const decimalPart = grade % 1;
  if (decimalPart >= 0.5) {
    return Math.ceil(grade);
  } else {
    return Math.floor(grade);
  }
}
