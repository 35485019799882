import React from 'react';
import BlueCard from 'ui/Cards/BlueCard';
import { Section, SubTitle } from '../CCBStatsAndGrades';
import StatRadarChart from './StatRadarChart';
import {
  Dot,
  Legend,
  Legends,
  SubTitleText,
} from '../RepartitionLca/RepartitionLca';
import styled, { useTheme } from 'styled-components';

export const RepartitionSpe = () => {
  const theme = useTheme();
  return (
    <BlueCard $bigPadding fullWidth maxWidth="60%">
      <Section>
        <SubTitle>Répartition de mes réponses par spécialité</SubTitle>
      </Section>
      <StyledLegends>
        <StyledLegend>
          <Dot fillColor={theme.colors.success.main} />
          <SubTitleText>Pleinement réussie (1 pt) </SubTitleText>
        </StyledLegend>
        <StyledLegend>
          <Dot fillColor={theme.colors.warning.dark} />
          <SubTitleText>En partie réussie</SubTitleText>
        </StyledLegend>
        <StyledLegend>
          <Dot fillColor={theme.colors.error.dark} />
          <SubTitleText>Non maitrisée (0 pt)</SubTitleText>
        </StyledLegend>
      </StyledLegends>
      <StatRadarChart />
    </BlueCard>
  );
};

const StyledLegends = styled(Legends)`
  justify-content: space-around;
`;

const StyledLegend = styled(Legend)`
  width: auto;
`;
