import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getFQPieChartFromAPI } from 'http/flash-questions/pie-chart/api';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetPieChart = () => {
  const { data, ...otherProps } = useQuery(
    ['fqPieChart'],
    () => getFQPieChartFromAPI(),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  const pieChartData = useMemo(() => {
    return [
      {
        value: data?.new_cards,
      },
      {
        value: data?.to_review_cards,
      },
      {
        value: data?.acquired_cards,
      },
    ];
  }, [data]);

  const totalNumOfQuestions = useMemo(() => {
    return data
      ? data?.new_cards + data?.to_review_cards + data?.acquired_cards
      : undefined;
  }, [data]);

  return { pieChartData, totalNumOfQuestions, ...otherProps };
};
