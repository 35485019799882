import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'ui/Checkbox';
import { ColumnHeaderWrapper } from 'pages/selection/components/ColumnHeaderWrapper';
import { FC, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useTableConfigContext } from '../contexts/tableConfigContext';
import { ItemInfos } from 'store/types';
import {
  checkIfAllItemsAreSelected,
  getItemsNotAlreadyInOtherItems,
} from './utils';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { useShowSelectedItemsContext } from '../contexts/showSelectedItemsContext';

interface Props {
  pageItemInfos: ItemInfos[];
}

export const CheckboxHeader: FC<Props> = ({ pageItemInfos }) => {
  const dispatch = useDispatch();

  const { tableStateSelector, updateSelectionCreator } =
    useTableConfigContext();

  const selectedItemsInfos = useSelector(
    (state: RootState) => tableStateSelector(state).selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const areAllCurrentItemsSelected: boolean = useMemo(
    () => checkIfAllItemsAreSelected(pageItemInfos, selectedItemsInfos),
    [pageItemInfos, selectedItemsInfos]
  );

  const isUserFree = useIsUserFree();

  useEffect(() => {
    if (pageItemInfos.length === 0) {
      setIsSelected(false);
    } else {
      setIsSelected(areAllCurrentItemsSelected ? true : false);
    }
  }, [pageItemInfos, selectedItemsInfos, areAllCurrentItemsSelected]);

  const handleCheckboxClick = () => {
    if (areAllCurrentItemsSelected) {
      const selectedItemsWithoutPageItems = getItemsNotAlreadyInOtherItems(
        selectedItemsInfos,
        pageItemInfos
      );
      dispatch(updateSelectionCreator(selectedItemsWithoutPageItems));
    } else {
      const pageItemsWithoutAlreadySelected = getItemsNotAlreadyInOtherItems(
        pageItemInfos,
        selectedItemsInfos
      );

      const newItemsWithAllCurrent = [
        ...selectedItemsInfos,
        ...pageItemsWithoutAlreadySelected,
      ];
      dispatch(updateSelectionCreator(newItemsWithAllCurrent));
    }
  };

  const { toggleShouldShowSelectedItems, shouldShowSelectedItems } =
    useShowSelectedItemsContext();

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isCheckbox>
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxClick}
            disabled={isUserFree}
          />
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuItem
        onClick={(e) => {
          toggleShouldShowSelectedItems();
        }}
      >
        {shouldShowSelectedItems ? 'Montrer tout' : 'Afficher la sélection'}
      </MenuItem>
    </Menu>
  );
};
