import { FC } from 'react';
import styled from 'styled-components/macro';
import { useClickedQuestionContext } from '../clickedQuestionContext';
import { CorrectionGroupRecap } from './CorrectionGroupRecap';
import { CorrectionTaskResponse } from 'types/correction.type';
import { NewsletterCard } from 'components/Newsletter/NewsletterCard';

interface Props {
  taskCorrection: CorrectionTaskResponse;
  selectedGroupId?: number;
  setSelectedGroupId: (newVal: number) => void;
}

export const CorrectionGroupsRecap: FC<Props> = ({
  taskCorrection,
  selectedGroupId,
  setSelectedGroupId,
}) => {
  const { setBlockToggle, blockToggle } = useClickedQuestionContext();

  const handleToggle = (groupId: number) => {
    if (groupId !== selectedGroupId) {
      setSelectedGroupId(groupId);
    } else {
      setBlockToggle(!blockToggle);
    }
  };

  return (
    <Container>
      {taskCorrection.correction_groups.map((group) => (
        <CorrectionGroupRecap
          groupData={group}
          isOpen={selectedGroupId === group.id}
          toggleOpen={() => handleToggle(group.id)}
          key={group.id}
        />
      ))}
      <NewsletterCard />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 4px;
`;
