import styled from 'styled-components';
import { FlashCardsStats } from './components/FlashCardsStats/FlashCardsStats';
import { TrainingStats } from './components/TrainingStats/TrainingStats';

export const Home = () => {
  return (
    <Container>
      <TrainingStats />
      <FlashCardsStats />
    </Container>
  );
};

const Container = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
`;
