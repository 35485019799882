import { device } from 'helpers/css/responsive';
import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';
import { SpecialitiesHeader } from '../Specialties/SpecialtiesHeader';
import { useGetGradesForAKnowledge } from 'http/selection/get_knowledge/connector';
import { useClickedQuestionContext } from '../../clickedQuestionContext';
import RankLetter from 'ui/Misc/RankLetter';

import {
  formatGradeOutOfTwenty,
  formatGradePointsCoeff,
  GradeData,
  formatGradeForARank,
  formatGradeForARankOutOfTwenty,
  isMoreThanFourteenOutOfTwenty,
} from './points-formatter';
import { CorrectionGroup } from 'types/correction.type';

interface Props {
  groupData: CorrectionGroup;
}

export const CorrectionGroupHeader: FC<Props> = ({ groupData }) => {
  const userGradeData: GradeData = {
    grade: groupData.grade,
    totalCoeff: groupData.total_coefficient ?? 1,
  };
  const { isPointPerQuestion } = useClickedQuestionContext();

  const { aRankGradeData, isThereARankQuestion } =
    useGetGradesForAKnowledge(groupData);

  const medianData: GradeData = {
    grade: groupData.group?.median ?? null,
    totalCoeff: groupData.total_coefficient ?? 1,
  };

  return (
    <>
      <TitleHeader>
        {groupData.custom_qi ? (
          'Questions Isolées'
        ) : (
          <>
            <Title>
              {groupData.group.collection} -
              {groupData.group?.composition_unit
                ? `UC${groupData.group?.composition_unit}`
                : ''}{' '}
              {groupData.group.type} {groupData.group.num}
            </Title>

            <SpecialitiesHeader
              speciality1={groupData.group?.specialty1}
              speciality2={groupData.group?.specialty2}
            />
          </>
        )}
      </TitleHeader>

      <HeaderTable>
        <tbody>
          <GradeRow>
            <HeaderRowCell>Note</HeaderRowCell>
            <HeaderRowCell className="correction-section-note">
              {'Note globale '}
              {isPointPerQuestion
                ? formatGradePointsCoeff(userGradeData)
                : formatGradeOutOfTwenty(userGradeData)}
            </HeaderRowCell>
            <HeaderRowCell>
              <RankAContainer>
                <GradeInfosContainer>
                  <Tippy
                    content={
                      <>
                        Il faut obtenir une note ≥ 14/20 sur les questions dites
                        “fermées” (QRU, QRP, QROC, QZP) portant sur une
                        connaissance de rang A.
                      </>
                    }
                  >
                    <RankLetter letter={'A'}>A</RankLetter>
                  </Tippy>
                  {isThereARankQuestion ? (
                    <>
                      <AGradeContainer>
                        {isPointPerQuestion
                          ? formatGradeForARank(aRankGradeData)
                          : formatGradeForARankOutOfTwenty(aRankGradeData)}
                      </AGradeContainer>
                      {!isMoreThanFourteenOutOfTwenty(
                        formatGradeForARankOutOfTwenty(aRankGradeData)
                      ) && (
                        <Tippy
                          content={
                            'Note inférieure au seuil fixé par le concours (<14/20)'
                          }
                        >
                          <WarningSign>!</WarningSign>
                        </Tippy>
                      )}{' '}
                    </>
                  ) : (
                    <AGradeContainer>--/--</AGradeContainer>
                  )}
                </GradeInfosContainer>
              </RankAContainer>
            </HeaderRowCell>
          </GradeRow>
          {groupData.custom_qi ? (
            <Row>
              <HeaderRowCell></HeaderRowCell>
              <HeaderRowCell></HeaderRowCell>
              <HeaderRowCell></HeaderRowCell>
            </Row>
          ) : (
            medianData.grade !== null && (
              <Row>
                <HeaderRowCell>
                  <TitleSecondLine>Médiane nationale</TitleSecondLine>
                </HeaderRowCell>
                <HeaderRowCell>
                  <MedianeInfoContainer>
                    Note globale{' '}
                    {isPointPerQuestion
                      ? formatGradePointsCoeff(medianData)
                      : formatGradeOutOfTwenty(medianData)}
                  </MedianeInfoContainer>
                </HeaderRowCell>
                <HeaderRowCell></HeaderRowCell>
              </Row>
            )
          )}
        </tbody>
      </HeaderTable>
    </>
  );
};

const TitleHeader = styled.div`
  padding: 16px var(--horizontal-padding);
  display: flex;
  flex-wrap: wrap;
  gap: 12px 40px;
  width: 100%;
`;

const Title = styled.h3``;

const HeaderTable = styled.table`
  border-collapse: collapse;
`;

const HeaderRowCell = styled.td`
  padding: 12px 8px;
  font-size: 20px;
  white-space: nowrap;
  size: ${({ theme }) => theme.typography.h2};
  @media ${device.desktop} {
    padding: 12px var(--horizontal-padding);
  }
`;

const Row = styled.tr`
  ${HeaderRowCell}:first-child {
    padding-left: 28px;
    width: 100%;
    max-width: 346px;
  }

  ${HeaderRowCell}:last-child {
    padding-right: 28px;
  }
`;

const GradeRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary.light};
  ${HeaderRowCell} {
    ${({ theme }) => theme.typography.label}
  }
`;

const MedianeInfoContainer = styled.div`
  font-size: 20px;
`;

const TitleSecondLine = styled.div`
  font-size: 18px;
`;

const RankAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const GradeInfosContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WarningSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.error.main};
`;

const AGradeContainer = styled.div`
  margin: 0 16px;
`;
