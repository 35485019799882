import styled from 'styled-components';
import { FC, useState } from 'react';
import { ArrowUp } from '../icons/ArrowUp';
import { ArrowDown } from '../icons/ArrowDown';
import parse from 'html-react-parser';
import { StyledMathJax } from 'ui/MathJax';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';

export interface Subjects {
  title: string;
  content: string;
}
interface SubjectBlockProps {
  subject: Subjects;
}

export const SubjectBlock: FC<SubjectBlockProps> = ({ subject }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <CorrectionThemeProvider>
      <SubjectBlockContainer>
        <ClickableHeader
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="subject-block-body-container"
        >
          <TitleContainer>
            <Title>{subject.title}</Title>
            <IconArrow>
              {isOpen && subject.content ? <ArrowUp /> : <ArrowDown />}
            </IconArrow>
          </TitleContainer>
          {isOpen && (
            <SubjectContentContainer className="subject-block-content">
              {subject.content && (
                <StyledMathJax inline>{parse(subject.content)}</StyledMathJax>
              )}
            </SubjectContentContainer>
          )}
        </ClickableHeader>
      </SubjectBlockContainer>
    </CorrectionThemeProvider>
  );
};

const SubjectBlockContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Title = styled.div`
  font-size: 16px;
`;

const ClickableHeader = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.compass.dark};
  border-radius: 8px;
  padding: 12px;
  font-size: ${({ theme }) => theme.typography.body};
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;
`;
const IconArrow = styled.div`
  min-height: 16px;
  min-width: 16px;
  transition: transform 0.2s ease;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SubjectContentContainer = styled.div`
  margin-top: 160px;
  display: flex;
  align-items: start;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.body};
`;
