import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { updateStatsActiveTab } from 'store/slices/ccbSlice';
import { Statstabs } from 'http/ccb/type';
import { device } from 'helpers/css/responsive';

export const TabSwitch = () => {
  const dispatch = useDispatch();
  const { statsActiveTab } = useSelector((state: any) => state.ccb);

  const handleClick = (clickedTab: Statstabs) => {
    if (clickedTab !== statsActiveTab) {
      dispatch(updateStatsActiveTab(clickedTab));
    }
  };

  return (
    <SwitchContainer id="astrolabe-switcher">
      <SwitchButton
        isActive={statsActiveTab === 'Classement'}
        onClick={() => handleClick('Classement')}
      >
        <TextContainter>
          <Text>Classement</Text>
        </TextContainter>

        <Underline isActive={statsActiveTab === 'Classement'} />
      </SwitchButton>
      <SwitchButton
        isActive={statsActiveTab === 'Statistique'}
        onClick={() => handleClick('Statistique')}
      >
        <TextContainter>
          <Text>Statistiques</Text>
        </TextContainter>
        <Underline isActive={statsActiveTab === 'Statistique'} />
      </SwitchButton>
      <SwitchButton
        isActive={statsActiveTab === 'Correction'}
        onClick={() => handleClick('Correction')}
      >
        <TextContainter>
          <Text>Copies corrigées</Text>
        </TextContainter>
        <Underline isActive={statsActiveTab === 'Correction'} />
      </SwitchButton>
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;

  white-space: nowrap;
`;

const SwitchButton = styled.div<{ isActive: boolean }>`
  border-radius: 6px;
  gap: 16px;
  color: ${({ isActive }) =>
    isActive
      ? ({ theme }) => theme.colors.secondary.main
      : ({ theme }) => theme.colors.inactive};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: ${({ isActive }) =>
      isActive
        ? ({ theme }) => theme.colors.secondary.main
        : ({ theme }) => theme.colors.inactive};
  }
`;

const Underline = styled.div<{ isActive: boolean }>`
  height: ${({ isActive }) => (isActive ? '2px' : '1px')};
  position: relative;
  z-index: ${({ isActive }) => (isActive ? '1' : '0')};
  margin-top: ${({ isActive }) => (isActive ? '0.5rem' : 'calc(0.5rem + 1px)')};
  width: 100%;
  min-width: calc(100% + 16px);
  border-radius: 2px;
  border: ${({ isActive }) =>
    isActive
      ? ({ theme }) => `2px solid ${theme.colors.secondary.main}`
      : ({ theme }) => `1px solid ${theme.colors.inactive}`};
  &:hover {
    color: ${({ isActive }) =>
      isActive
        ? ({ theme }) => theme.colors.secondary.main
        : ({ theme }) => theme.colors.inactive};
  }
  @media ${device.mobile} {
    min-width: 100%;
  }
`;

const TextContainter = styled.div`
  padding: 0.5rem 1.5rem;
  @media ${device.mobile} {
    padding: 2px;
  }
`;

const Text = styled.h4`
  font-weight: 700;
`;
