import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { DotPointer } from 'ui/DotWithText';
import { ValidateButton } from '../ui/ValidateButton';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { StyledMathJax } from 'ui/MathJax';
import { buildURL } from 'auth/authentifiedHttpCalls';
import QZPModal from './QZPModal';
import { useAtom } from 'jotai';
import { isQZPModalOpenAtom } from 'pages/new_training/atoms';
import { getQZPColors } from './qzpColors';
import { HALF_DOT_WIDTH } from 'pages/new_training/components/QZP/SingleAnswer';
import { toast } from 'react-toastify';
import { useSaveQuestionQZP } from 'pages/new_training/hooks/saveHooks/useSaveQZP';

export interface Props {
  question: string;
  questionId: number;
  questionNumber: number;
  answer: {
    id: number;
    question_item: {
      image: string;
      labels: Array<string>;
    };
    answer: {
      [key: string]: {
        x: number;
        y: number;
      };
    };
  };
  saved: boolean;
  show: boolean;
  isQI: boolean;
  shouldUpdateArrowsPositions?: boolean;
  onSave(): void;
  onModification?(): void;
  anonymous: boolean;
  collection: string | undefined;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const QZP = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const titleRef = useRef<HTMLDivElement>(null);

  const [validationModal, setValidationModal] = useState<boolean>(false);
  const [answer, setAnswer] = useState<Props['answer']>(props.answer);
  const answerRef = useRef<Props['answer']>(props.answer);
  const [responseModal, setResponseModal] = useAtom(
    isQZPModalOpenAtom(props.questionId)
  );

  const saveQuestion = useSaveQuestionQZP(props.onSave);

  const onDragStop = (
    index: number,
    relativeDistances: { x: number; y: number }
  ) => {
    const newAnswer = Object.assign({}, answer);
    newAnswer.answer[answer.question_item.labels[index]] = relativeDistances;
    setAnswer(newAnswer);
  };

  useEffect(() => {
    setAnswer(props.answer);
  }, [props.answer]);

  useEffect(() => {
    if (props.show && !props.isQI) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [props.show]);

  const handleValidationClick = () => {
    if (props.isQI) {
      handleSaveQuestion();
    } else {
      setValidationModal(true);
    }
  };

  const handleSaveQuestion = async () => {
    props.setIsLoading(true);
    if (!responseModal) {
      const params = {
        id: props.questionId,
        answers: {
          id: answer.id,
          answer: answer.answer,
        },
      };
      try {
        await saveQuestion(params);
        answerRef.current = answer;
      } catch (err) {
        toast.error(
          'Erreur lors de la sauvegarde de la question, veuillez réessayer'
        );
      }
    }

    props.setIsLoading(false);
  };

  useEffect(() => {
    if (answerRef.current !== answer) {
      props.isQI && props.onModification && props.onModification();
    }
    // eslint-disable-next-line
  }, [answer]);

  if (!answer) return null;

  const colors = getQZPColors(answer.question_item?.labels.length);

  return (
    <Container ref={ref} show={props.show}>
      <Title ref={titleRef}>
        {(props.anonymous ? '' : props.isQI ? props.collection + ' ' : '') +
          (props.isQI
            ? 'QI ' + props.questionNumber
            : 'Question ' + props.questionNumber) +
          ' - Question zone à pointer'}
      </Title>
      <Statement>
        <StyledMathJax inline>{parse(props.question)}</StyledMathJax>
      </Statement>
      <PaddingContainer>
        <ImageContainer>
          <PreviewImage
            src={buildURL('/media/' + props.answer.question_item.image)}
            alt="Question zone à pointer"
            onClick={() => setResponseModal(true)}
          />
          {Object.keys(answer.answer).map((key) => (
            <ArrowContainer
              relativeX={answer.answer[key].x * 100}
              relativeY={answer.answer[key].y * 100}
              key={key}
            >
              {answer.answer[key].y !== 1.08 && (
                <DotPointer
                  color={
                    colors[props.answer.question_item.labels.indexOf(key) % 5]
                  }
                  index={props.answer.question_item.labels.indexOf(key)}
                />
              )}
            </ArrowContainer>
          ))}

          {responseModal && (
            <QZPModal
              onClose={() => setResponseModal(false)}
              answer={props.answer}
              onDragStop={onDragStop}
              shouldUpdateArrowsPositions={props.shouldUpdateArrowsPositions}
              isQI={props.isQI}
              saved={props.saved}
            />
          )}
        </ImageContainer>
        <ImageCallToAction>
          (Cliquez sur l'image pour répondre)
        </ImageCallToAction>
      </PaddingContainer>

      <ValidateButton
        show={props.isQI || !props.saved}
        onClick={handleValidationClick}
        isLoading={props.isLoading}
      />
      <ValidationModal
        isOpen={validationModal}
        onCancel={() => setValidationModal(false)}
        onAccept={() => {
          setValidationModal(false);
          handleSaveQuestion();
        }}
        title="Réponse définitive"
        text="En cliquant sur le bouton OK vous confirmez vos réponses. Elles ne seront plus modifiables."
      />
    </Container>
  );
});

const Container = styled.div<{ show?: boolean }>`
  margin-bottom: 50px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Title = styled.div`
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #222529;
`;

const Statement = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  sup {
    font-size: 0.83em;
  }

  p,
  span {
    font-family: 'FreeSans' !important;
  }

  img {
    max-width: 60%;
    height: auto;
  }
`;
const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const PaddingContainer = styled.div`
  padding: 20px;
  max-width: 500px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ImageCallToAction = styled.p`
  margin: 0;
  font-style: italic;
  text-align: center;
  font-size: 0.9rem;
`;
const ArrowContainer = styled.div<{ relativeX: number; relativeY: number }>`
  position: absolute;
  top: calc(${({ relativeY }) => relativeY}% - ${HALF_DOT_WIDTH}px);
  left: calc(${({ relativeX }) => relativeX}% - ${HALF_DOT_WIDTH}px);
  max-height: fit-content;
  max-width: fit-content;
`;
