import { css } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { Typographies } from '.';

export const compassTypographies: Typographies = {
  small: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;

    @media ${device.desktop} {
      font-size: 10px;
      line-height: 12px;
    }
  `,
  textBody2: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 10px;

    @media ${device.desktop} {
      font-size: 12px;
      line-height: 14px;
    }
  `,
  label: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;

    @media ${device.desktop} {
      font-size: 12px;
      line-height: 14px;
    }
  `,
  boldLabel: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;

    @media ${device.desktop} {
      font-size: 12px;
      line-height: 14px;
      font-weight: 900;
    }
  `,
  h1: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    @media ${device.desktop} {
      font-size: 24px;
      line-height: 24px;
    }
  `,
  h2: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    @media ${device.desktop} {
      font-size: 16px;
      line-height: 18px;
    }
  `,
  h3: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    @media ${device.desktop} {
      font-size: 15px;
      line-height: 19px;
    }
  `,
  h4: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    @media ${device.desktop} {
      font-size: 24px;
      line-height: 28px;
    }
  `,
  h5: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    @media ${device.desktop} {
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
    }
  `,
  body: css`
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    @media ${device.desktop} {
      font-size: 12px;
      line-height: 20px;
    }
  `,
  button: css`
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    @media ${device.desktop} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  a: css`
    color: #0000ee;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: #0000ee;
    }
  `,
  fontFamily: css`
    font-family: 'Urbanist', sans-serif;
  `,
} as const;
