import styled, { useTheme } from 'styled-components';
import { Button } from 'ui/Button';
import { FQDecksTable } from './table/FQDecksTable';
import { InfoBlock } from './components/FQRow/DailyCards';
import { CloseIcon } from 'ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { reset, setMode } from 'store/slices/fqCurrentSlice';
import { FQMode } from 'pages/flash-questions/types';
import { device } from 'helpers/css/responsive';
import { useEffect } from 'react';
import { Settings } from 'ui/icons/Settings';
import { setFlashQuestionSettingsModal } from 'store/slices/fqSettingsSlice';
import FQSettingsModal from '../../modals/FQSettingsModal';
import { RootState } from 'store';
import { Status } from 'store/types';
import { useQueryClient } from 'react-query';
import { USE_GET_USER_OPTIONS_KEY } from 'modules/user-options/hooks/useGetUserOptions';

export const FQDeckList = () => {
  const {
    colors: { primary, secondary, success, flashQuestions },
  } = useTheme();

  const client = useQueryClient();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(
    (state: RootState) => state.fqSettings.showFlashQuestionSettingsModal
  );
  const setFQParametersStatus = useSelector(
    (state: RootState) => state.fqSettings.status.postParameters
  );

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeckCreation = () => {
    dispatch(setMode({ mode: FQMode.DECKCREATION }));
  };

  const handleSettings = () => {
    dispatch(setFlashQuestionSettingsModal(true));
  };

  useEffect(() => {
    if (setFQParametersStatus === Status.RESOLVED) {
      client.invalidateQueries(USE_GET_USER_OPTIONS_KEY);
    }
  }, [setFQParametersStatus, client]);

  return (
    <>
      <FQSettingsModal
        isOpen={isModalOpen}
        closeModal={() => dispatch(setFlashQuestionSettingsModal(false))}
      />
      <Container>
        <Layout>
          <TitleRow style={{ position: 'relative' }}>
            <h1>Questions flash</h1>
          </TitleRow>

          <DecksHeader>
            <StyledButton onClick={handleDeckCreation} id="fq-question-button">
              <AddLogo />
              Nouveau paquet
            </StyledButton>

            <SecondElement>
              <CardsResume>
                <InfoBlock2
                  bg={flashQuestions.blueLightTranslucent}
                  color={primary.dark}
                >
                  Nouvelles cartes du jour
                </InfoBlock2>
                <InfoBlock2
                  bg={flashQuestions.redLightTranslucent}
                  color={secondary.dark}
                >
                  Cartes à revoir du jour
                </InfoBlock2>
                <InfoBlock2
                  bg={flashQuestions.greenLightTranslucent}
                  color={success.dark}
                >
                  Cartes à réviser du jour
                </InfoBlock2>
              </CardsResume>

              <SettingsContainer onClick={handleSettings}>
                <SettingsIcon />
              </SettingsContainer>
            </SecondElement>
          </DecksHeader>

          <FQDecksTable />
        </Layout>
      </Container>
    </>
  );
};

const SecondElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;
const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    scale: 1.05;
  }

  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;
const SettingsIcon = styled(Settings)`
  height: 30px;
  width: 30px;

  margin-top: 10px;
  margin-left: 0;
  @media ${device.desktop} {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Layout = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 16px;

  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`;

const DecksHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  margin: 1em 0 1em 0;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: fit-content;
  @media ${device.mobile} {
    width: auto;
  }
  white-space: nowrap;
  height: fit-content;
  align-self: center;

  margin-bottom: 8px;
  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;

const CardsResume = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
  margin-top: 10px;
  //apply a margin-top on childrens except the first one
  & > *:not(:first-child) {
    margin-top: 0.5em;
  }

  @media ${device.desktop} {
    margin-top: 0;
  }

  @media ${device.mobile} {
    flex-direction: row;
    & > *:not(:first-child) {
      margin-top: 0;
    }
  }
`;

const InfoBlock2 = styled(InfoBlock)`
  margin-left: 0;
  @media ${device.mobile} {
    margin-left: 1em;
  }
  font-weight: bold;
  padding: 0.2em 0.5em;
`;

const AddLogo = styled(CloseIcon)`
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.2em;
  transform: rotate(45deg);
`;
