import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { CorrectionQuestion } from 'types/correction.type';
import { adaptTCSCorrectionData } from './tcsCorrectionDataAdapter';
import { Comment } from './Comment';

interface Props {
  questionData: CorrectionQuestion;
}

export const TCSCorrectionBlock: FC<Props> = ({ questionData }) => {
  const correctionData = useMemo(() => {
    return adaptTCSCorrectionData(questionData);
  }, [questionData]);

  return (
    <Container>
      <Header>{correctionData.firstColumnHeader}</Header>
      <Header>{correctionData.secondColumnHeader}</Header>
      <Header>{correctionData.thirdColumnHeader}</Header>
      <Header></Header>
      {correctionData.rows.map((row) => (
        <Comment key={row.id} row={row} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr 400px 16px;
  padding: 0 24px;
`;

const Header = styled.div`
  padding: 1rem;
  border-bottom: 1px solid black;
  display: grid;
  place-items: flex-start;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
`;
