import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { device } from 'helpers/css/responsive';
import { useIsActiveUrl } from 'hooks/useIsActiveUrl';
import { useMenuContext } from 'components/MainLayout/menuContext';

import { menuOpenTransitionConfig } from '../../cssTransitions';
import { MenuIconProps } from '../../../../ui/icons/MenuIcons/types';
import { MenuItemBookmarkTip } from './MenuItemBookmarkTip';

interface Props {
  icon: React.FC<MenuIconProps>;
  text: string;
  to: string;
  isLinkToPage?: boolean;
  isHighlighted?: boolean;
  isNew?: boolean;
  id: string;
  onClick?: () => void;
}

export const MenuItem: FC<Props> = ({
  icon: Icon,
  text,
  to,
  isLinkToPage = true,
  isHighlighted,
  isNew,
  onClick,
  id,
}) => {
  const { isMenuExpanded, closeMenu } = useMenuContext();
  const isActive = useIsActiveUrl(to);

  return (
    <Tippy
      content={text}
      disabled={isMenuExpanded}
      arrow={false}
      placement="right-start"
    >
      <UnstyledLink
        to={to}
        onClick={
          onClick
            ? (event) => {
                event.preventDefault();
                onClick();
              }
            : undefined
        }
        id={id}
      >
        <Container
          isActive={isActive}
          isMenuExpanded={isMenuExpanded}
          onClick={closeMenu}
          isHighlighted={isHighlighted}
        >
          {isNew && <Badge />}
          <IconContainer>
            <Icon isFilled={isActive} />
          </IconContainer>
          <Text isActive={isActive} isMenuExpanded={isMenuExpanded}>
            {text}
          </Text>

          {isLinkToPage && (
            <MenuItemBookmarkTip
              isMenuExpanded={isMenuExpanded}
              isActive={isActive}
            />
          )}
        </Container>
      </UnstyledLink>
    </Tippy>
  );
};

const Container = styled.div<{
  isActive?: boolean;
  isMenuExpanded: boolean;
  isHighlighted?: boolean;
}>`
  --menuBackgroundColor: ${({ theme }) => theme.colors.primary.main};

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          --idleBackgroundColor: ${({ theme }) => theme.colors.secondary.main};
          --hoveredBackgroundColor: ${({ theme }) =>
            theme.colors.secondary.light};
          --activeBackgroundColor: ${({ theme }) =>
            theme.colors.secondary.main};
          --bookmarkTipColor: var(--activeBackgroundColor);

          --textColor: ${({ theme }) => theme.colors.secondary.contrastText};
        `
      : css`
          --idleBackgroundColor: ${({ theme }) => theme.colors.primary.main};
          --hoveredBackgroundColor: ${({ theme }) =>
            theme.colors.background.main + '88'};
          --activeBackgroundColor: ${({ theme }) =>
            theme.colors.background.main};
          --bookmarkTipColor: var(--activeBackgroundColor);

          --textColor: ${({ theme }) => theme.colors.primary.dark};
        `}

  ${({ theme, isActive, isMenuExpanded }) => css`
    /* Allow bookmark style elements positioning */
    position: relative;

    display: flex;
    align-items: center;
    gap: 24px;

    padding: 8px 12px;
    border-radius: ${theme.borderRadius.cta.mobile};

    ${isActive
      ? css`
          background-color: var(--activeBackgroundColor);
        `
      : css`
          background-color: var(--idleBackgroundColor);
          &:hover {
            background-color: var(--hoveredBackgroundColor);
          }
        `}

    @media ${device.desktop} {
      padding: 12px 10px;
      border-radius: ${({ theme }) => theme.borderRadius.cta.desktop};
    }

    /* Handle menu expansion/shrink */
    ${isMenuExpanded
      ? css`
          width: 100%;
          opacity: 1;
        `
      : css`
          width: 0;
          opacity: 0;

          @media ${device.desktop} {
            opacity: 1;
            width: 44px;
          }
        `}

    /* MenuItem bookmark like style */
    @media ${device.desktop} {
      ${!isMenuExpanded &&
      isActive &&
      css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        width: 52px;
      `}
    }

    transition-property: width, opacity, padding, background-color,
      border-radius;
    ${menuOpenTransitionConfig};
  `};
`;

const UnstyledLink = styled(Link)`
  /* Override link default styles */
  text-decoration: none !important;
`;

const IconContainer = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  color: var(--textColor);
  @media ${device.desktop} {
    height: 24px;
    width: 24px;
  }
`;

const Badge = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  z-index: 3;
`;

const Text = styled.span<{ isActive?: boolean; isMenuExpanded: boolean }>`
  ${({ theme, isActive, isMenuExpanded }) => css`
    /* Prevents unwanted line break when the menu expands/shrinks */
    white-space: nowrap;
    color: var(--textColor);

    ${isMenuExpanded
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

    transition-property: opacity;
    ${menuOpenTransitionConfig}

    ${isActive &&
    css`
      font-weight: 600;
    `}
  `}
`;
