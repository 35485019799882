import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { SmallDownArrow } from 'ui/icons/SmallDownArrow';
import { propositionStateToString } from './utils';
import { QCMPropositionState } from 'types/correction.type';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  propositionState: QCMPropositionState;
}

export const Toggler: FC<Props> = ({
  isOpen,
  toggleOpen,
  propositionState,
}) => {
  return (
    <Container type="button" onClick={toggleOpen}>
      <span>{propositionStateToString(propositionState)}</span>{' '}
      <Arrow isOpen={isOpen} />
    </Container>
  );
};

const Container = styled.button`
  all: unset;

  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: 2px 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

const Arrow = styled(({ isOpen, ...props }: { isOpen: boolean }) => (
  <SmallDownArrow {...props} />
))`
  --size: 8px;
  height: var(--size);
  width: var(--size);

  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
