export const ArrowSquareOut = (props: {
  className?: string;
  disableDarkMode?: boolean;
}) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7812 10.5469V4.21875H16.4531"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1875 11.8125L22.7812 4.21875"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4062 15.1875V21.9375C19.4062 22.1613 19.3174 22.3759 19.1591 22.5341C19.0009 22.6924 18.7863 22.7812 18.5625 22.7812H5.0625C4.83872 22.7812 4.62411 22.6924 4.46588 22.5341C4.30764 22.3759 4.21875 22.1613 4.21875 21.9375V8.4375C4.21875 8.21372 4.30764 7.99911 4.46588 7.84088C4.62411 7.68264 4.83872 7.59375 5.0625 7.59375H11.8125"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
