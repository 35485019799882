import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import { RadioText } from './components/RadioText';
import { RadioTextOptions } from './types';

interface Props<TValue> {
  options: RadioTextOptions<TValue>[];
  value?: TValue;
  onChange: (newValue: TValue) => void;
  small?: boolean;
}

export const RadioTexts: FC<Props<any>> = ({
  options,
  value,
  onChange,
  small: isSmall,
}) => {
  return (
    <Container isSmall={isSmall}>
      {options.map((option) => (
        <RadioText
          isSmall={isSmall}
          onClick={() => onChange(option.value)}
          isSelected={option.value === value}
          label={option.label}
          key={option.value}
        />
      ))}
    </Container>
  );
};

const Container = styled.div<{ isSmall?: boolean }>`
  display: flex;

  ${({ isSmall }) =>
    isSmall
      ? css`
          gap: 10px;
        `
      : css`
          gap: 28px;

          @media ${device.desktop} {
            gap: 44px;
          }
        `}
`;
