import anatomie_pat from './icons/light/anatomie_pat.svg';
import cancero from './icons/light/cancero.svg';
import cardio from './icons/light/cardio.svg';
import chir_dig from './icons/light/chir_dig.svg';
import chir_ortho from './icons/light/chir_ortho.svg';
import CMF from './icons/light/CMF.svg';
import dermato from './icons/light/dermato.svg';
import endo from './icons/light/endo.svg';
import epidemio from './icons/light/epidemio.svg';
import genetique from './icons/light/genetique.svg';
import geriatrie from './icons/light/geriatrie.svg';
import gyneco from './icons/light/gyneco.svg';
import hemato from './icons/light/hemato.svg';
import HGE from './icons/light/HGE.svg';
import immuno from './icons/light/immuno.svg';
import infectio from './icons/light/infectio.svg';
import med_leg from './icons/light/med_leg.svg';
import med_travail from './icons/light/med_travail.svg';
import MPR from './icons/light/MPR.svg';
import nephro from './icons/light/nephro.svg';
import neuro from './icons/light/neuro.svg';
import ophtalmo from './icons/light/ophtalmo.svg';
import ORL from './icons/light/ORL.svg';
import pediatrie from './icons/light/pediatrie.svg';
import pneumo from './icons/light/pneumo.svg';
import psy from './icons/light/psy.svg';
import rea from './icons/light/rea.svg';
import rhumato from './icons/light/rhumato.svg';
import sante_pub from './icons/light/sante_pub.svg';
import soin_pal from './icons/light/soin_pal.svg';
import therapeutique from './icons/light/therapeutique.svg';
import uro from './icons/light/uro.svg';

const iconMap = {
  ACP: anatomie_pat,
  CMF: CMF,
  COT: chir_ortho,
  CVD: chir_dig,
  DEP: epidemio,
  DER: dermato,
  DIA: epidemio,
  DSP: soin_pal,
  EDN: endo,
  EPI: epidemio,
  GEN: genetique,
  GER: geriatrie,
  GYN: gyneco,
  GYO: gyneco,
  HEM: hemato,
  HGE: HGE,
  LCA: epidemio,
  MCV: cardio,
  MDT: med_travail,
  MII: immuno,
  MIT: infectio,
  MLE: med_leg,
  MPR: MPR,
  NCH: neuro,
  NEP: nephro,
  NEU: neuro,
  ONC: cancero,
  OPH: ophtalmo,
  ORL: ORL,
  PED: pediatrie,
  PNE: pneumo,
  PSY: psy,
  REA: rea,
  RHU: rhumato,
  SPU: sante_pub,
  THE: epidemio,
  TPT: therapeutique,
  URO: uro,
};

function speHasIcon(trigram: string): trigram is keyof typeof iconMap {
  return Object.keys(iconMap).includes(trigram);
}

export function getSpeIcon(trigram: string) {
  if (speHasIcon(trigram)) {
    return iconMap[trigram];
  }

  return sante_pub;
}
