import { FC } from 'react';
import styled from 'styled-components';
import { Book } from 'http/compass/types';
import { ArrowSquareOut } from '../icons/ArrowSquareOut';
import { useCompassInfoContext } from '../../context/compassInfoContext';

interface RelatedBooksProps {
  books: Book[] | undefined;
}

export const RelatedBooks: FC<RelatedBooksProps> = ({ books }) => {
  const { history, setActiveTab, setActiveBook, setHistory } =
    useCompassInfoContext();

  const navigateToBooks = (bookId: number) => {
    const newHistoryItem = [...history, { tab: 'books', id: bookId }];
    setActiveBook(bookId);
    setHistory(newHistoryItem);
    setActiveTab('books');
  };

  return (
    <BooksRelatedContainer>
      <LabelContainer>Livre(s) ou l'item est présent</LabelContainer>
      {books?.map((book) => (
        <BookContainer key={book.id} onClick={() => navigateToBooks(book.id)}>
          {book.short_title}
          <IconContainer>
            <ArrowSquareOut />
          </IconContainer>
        </BookContainer>
      ))}
    </BooksRelatedContainer>
  );
};

const BooksRelatedContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const BookContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 531px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: 6px;
  margin-top: 4px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  :hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const LabelContainer = styled.div`
  margin: 0 0 20px 0;
  font-size: 20px;
`;
