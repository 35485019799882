import { getAPI } from 'auth';
import { TrainingTaskRaw } from './types';

export const getTrainingTask = async (taskId: number) => {
  const response = await getAPI<TrainingTaskRaw>('/training/get_task', {
    params: { task_id: taskId },
  });
  // console.log('api getTrainingTask', response.data);
  return response.data;
};
