import styled from 'styled-components/macro';
import { useCompassInfoContext } from '../context/compassInfoContext';
import { BooksInfosDisplay } from './BooksView/BooksInfosDisplay';
import { ItemsInfosDisplay } from './Itemsview/ItemsInfosDisplay';

export const PageContent = () => {
  const { activeTab } = useCompassInfoContext();

  return (
    <PageContentContainer>
      {activeTab === 'books' ? <BooksInfosDisplay /> : <ItemsInfosDisplay />}
    </PageContentContainer>
  );
};

const PageContentContainer = styled.div`
  width: 100%;
`;
