import { SessionRecap as SessionRecapType } from 'modules/recap/domain/entities/entity';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { SessionPieChart } from './SessionPieChart';
import { Button } from 'ui/Button';
import { useNavigate } from 'react-router';

interface Props {
  sessionRecap: SessionRecapType | undefined;
  selectedSession: string;
  className?: string;
}

export const SessionRecap: FC<Props> = ({
  sessionRecap,
  selectedSession,
  className,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const numerator = sessionRecap?.doneDenom ?? sessionRecap?.numerator;
  const fillPercentage =
    sessionRecap && sessionRecap.denom && numerator
      ? (numerator / sessionRecap.denom) * 100
      : 0;

  return (
    <StatContainer>
      <SessionPieChart fillPercentage={fillPercentage} />
      <ProgressBarContainer>
        <StatName>DP</StatName>
        <ProgressBarStat
          fillingColor={theme.colors.secondary.dark}
          percentage={
            sessionRecap && sessionRecap.totalDp
              ? (sessionRecap.doneDp / sessionRecap.totalDp) * 100
              : 0
          }
        />
        <StatNumbers>
          {sessionRecap ? sessionRecap.doneDp + '/' + sessionRecap.totalDp : 0}
        </StatNumbers>
        <StatName>QI</StatName>
        <ProgressBarStat
          fillingColor={theme.colors.secondary.light}
          percentage={
            sessionRecap && sessionRecap.totalQi
              ? (sessionRecap.doneQi / sessionRecap.totalQi) * 100
              : 0
          }
        />
        <StatNumbers>
          {sessionRecap
            ? sessionRecap.doneQi + '/' + sessionRecap.totalQi
            : '0/0'}
        </StatNumbers>
        <StatName>LCA</StatName>
        <ProgressBarStat
          fillingColor={theme.colors.secondary.main}
          percentage={
            sessionRecap && sessionRecap.totalLca
              ? (sessionRecap.doneLca / sessionRecap.totalLca) * 100
              : 0
          }
        />
        <StatNumbers>
          {sessionRecap
            ? sessionRecap.doneLca + '/' + sessionRecap.totalLca
            : '0/0'}
        </StatNumbers>
        {selectedSession !== 'Annales' && (
          <ButtonContainer>
            <Button
              onClick={() =>
                navigate(
                  `/progress?currentView=sessionDetail&selectedSession=${selectedSession}`
                )
              }
            >
              Détails
            </Button>
            {fillPercentage === 100 && (
              <Button onClick={() => navigate('/easy-dispo')}>
                Rang simulé
              </Button>
            )}
          </ButtonContainer>
        )}
      </ProgressBarContainer>
    </StatContainer>
  );
};

const StatContainer = styled.div``;

const ProgressBarContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 20px;
  grid-row-gap: 1.75rem;
`;

const StatName = styled.span`
  ${({ theme }) => theme.typography.label}
`;

const StatNumbers = styled.span`
  ${({ theme }) => theme.typography.label}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  grid-column: 1 / -1;
  gap: 1.5rem;
`;
