import { SelectionGroupResult } from 'http/selection-group/types';
import { ColumnHeaderWrapper } from 'pages/selection/components/ColumnHeaderWrapper';
import { useTableConfigContext } from 'pages/selection/tableConfigContext';
import { CheckboxHeader } from 'pages/selection/TableHeaders/CheckboxHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Table, Thead, Tr, Th, Tbody } from 'ui/TableElements';
import { ExpandHeader } from './components/ExpandHeader';
import { QuestionGroupRow } from './QuestionGroupRow';
import { SubTh, SubTr } from './QuestionGroupTable';

export const ReadonlyGroupTable = () => {
  const { tableStateSelector } = useTableConfigContext();

  const selectedItems = useSelector(
    (state: RootState) => tableStateSelector(state).selectedItemsInfos
  );

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <CheckboxHeader pageItemInfos={selectedItems} />
          </Th>
          <ExpandHeader />
          <Th>
            <ColumnHeaderWrapper isReadOnly>Session</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>N°</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Spécialités</ColumnHeaderWrapper>
          </Th>
          <Th></Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>
              Dernier entraînement
            </ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Médiane</ColumnHeaderWrapper>
          </Th>
        </Tr>
        <SubTr>
          <th colSpan={5} />
          <SubTh>
            <ColumnHeaderWrapper isReadOnly isSub>
              Items
            </ColumnHeaderWrapper>
          </SubTh>
          <SubTh>
            <ColumnHeaderWrapper isReadOnly isSub>
              Catégories
            </ColumnHeaderWrapper>
          </SubTh>
          <SubTh>
            <ColumnHeaderWrapper isReadOnly isSub>
              Type
            </ColumnHeaderWrapper>
          </SubTh>
        </SubTr>
      </Thead>
      <Tbody>
        {selectedItems.map((rowData, index) => (
          <QuestionGroupRow
            rowData={rowData as SelectionGroupResult}
            index={index}
            key={rowData.id}
          />
        ))}
      </Tbody>
    </Table>
  );
};
