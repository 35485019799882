import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MyGradeCard } from './GradeCard';
import { ParticipantsCard } from './ParticipantsCard';
import { formatTodayDate } from 'helpers/utils';

export const MyStatsGlobal = () => {
  const { ccbStats } = useSelector((state: any) => state.ccb);

  return (
    <Container>
      <CardContainer>
        <ParticipantsCard
          grade={`${ccbStats?.total_participants} participants au total`}
          text={`Au ${formatTodayDate()}`}
        />
        <MyGradeCard
          ranks={['A']}
          grade={ccbStats?.total_passed_rank_A_percentage}
          text={'des étudiants ont validé les questions de rang A - Global'}
          percentage={true}
        />
        <MyGradeCard
          ranks={['A', 'B']}
          grade={ccbStats?.total_average_global_grade}
          text={'Note moyenne avec coefficient 1 pour le rang B  - Global'}
        />
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: fit-content;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  width: fit-content;
`;
