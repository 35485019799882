import { SortOptions } from 'pages/selection/constants';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash.debounce';

interface LessonsQueryParamsContext {
  itemIds: string[];
  setItemIds: (val: string[]) => void;
  knowledgesRank: string[];
  setKnowledgesRank: (val: string[]) => void;
  knowledges: string[];
  setKnowledges: (val: string[]) => void;
  sortOrder: SortOptions;
  setSortOrder: (val: SortOptions) => void;
  currentPage: number;
  setCurrentPage: (val: number) => void;
  pageSize: number;
  setPageSize: (val: number) => void;
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  debouncedSearchTerm: string;
}

const contextDefaultValue: LessonsQueryParamsContext = {
  itemIds: [],
  setItemIds: () => {},
  knowledgesRank: [],
  setKnowledgesRank: () => {},
  knowledges: [],
  setKnowledges: () => {},
  sortOrder: 'ASCENDING',
  setSortOrder: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  pageSize: 0,
  setPageSize: () => {},
  searchTerm: '',
  setSearchTerm: () => {},
  debouncedSearchTerm: '',
};

const lessonsQueryParamsContext = createContext(contextDefaultValue);

export const LessonsQueryParamsProvider: FC = ({ children }) => {
  const [itemIds, setItemIds] = useState<string[]>([]);

  const [knowledgesRank, setKnowledgesRank] = useState<string[]>([]);

  const [knowledges, setKnowledges] = useState<string[]>([]);

  const [sortOrder, setSortOrder] = useState<SortOptions>('ASCENDING');

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [pageSize, _setPageSize] = useState<number>(20);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const handleNewSearchterm = useMemo(
    () => debounce(setDebouncedSearchTerm, 500),
    [setDebouncedSearchTerm]
  );

  useEffect(() => {
    handleNewSearchterm(searchTerm);
  }, [handleNewSearchterm, searchTerm]);

  const setPageSize = (val: number) => {
    _setPageSize(val);
    setCurrentPage(0);
  };

  return (
    <lessonsQueryParamsContext.Provider
      value={{
        itemIds,
        setItemIds,
        knowledgesRank,
        setKnowledgesRank,
        knowledges,
        setKnowledges,
        sortOrder,
        setSortOrder,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize,
        searchTerm,
        setSearchTerm,
        debouncedSearchTerm,
      }}
    >
      {children}
    </lessonsQueryParamsContext.Provider>
  );
};

export const useLessonsQueryParamsContext = () =>
  useContext(lessonsQueryParamsContext);
