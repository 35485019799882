import { FC } from 'react';
import styled from 'styled-components';

import { Pagination } from 'components/Pagination/Pagination';
import { RadioTexts } from 'ui/RadioTexts/RadioTexts';
import { TextBody2 } from 'ui/TextBody2';

interface Props {
  totalCount: number;
  currentPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  pageSize: number;
  handleNewPageSize: (newSize: number) => void;
}

export const TablePagination: FC<Props> = ({
  totalCount,
  currentPage,
  handlePageClick,
  pageSize,
  handleNewPageSize,
}) => (
  <OptionsBottomLine>
    <Pagination
      pageCount={totalCount ? Math.ceil(totalCount / pageSize) : 0}
      forcePage={totalCount ? currentPage : -1}
      onPageChange={handlePageClick}
    />

    <PageSizeContainer>
      <TextBody2>Nombre d'éléments par page :</TextBody2>
      <RadioTexts
        small
        options={[
          { label: '20', value: 20 },
          { label: '50', value: 50 },
          { label: '100', value: 100 },
        ]}
        value={pageSize}
        onChange={handleNewPageSize}
      />
    </PageSizeContainer>
  </OptionsBottomLine>
);

const OptionsBottomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 4px;
`;

const PageSizeContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
`;
