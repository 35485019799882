import { FC } from 'react';
import Tippy from '@tippyjs/react';
import { Chip } from './Chip';
import styled from 'styled-components/macro';
import { Item as ItemType } from 'types/item.type';

interface Props {
  item: ItemType;
}

export const Item: FC<Props> = ({ item }) => {
  return (
    <Tippy
      content={<LineReturnContainer>{item.title}</LineReturnContainer>}
      arrow={false}
      key={item.item}
      placement="bottom"
    >
      <Chip>{item.item}</Chip>
    </Tippy>
  );
};

const LineReturnContainer = styled.div`
  white-space: pre-line;
`;
