import { MS_IN_AN_HOUR } from 'constants/date';
import { useQuery } from 'react-query';
import { getCurrentCCB, getCCBstatus, getLCAArticles } from './api';
import { CurrentCCB, CCBStatus } from './type';
import { useMemo } from 'react';

export const useGetCurrentCCB = () => {
  const query = useQuery(['getCurrentCCB'], () => getCurrentCCB(), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const currentCCB = useMemo<CurrentCCB | undefined>(
    () => query.data,
    [query.data]
  );
  // console.log('useGetCurrentCCB', currentCCB);
  return {
    ...query,
    currentCCB,
  };
};

export const useGetStatus = (CCBid: number) => {
  const query = useQuery(['ccbCreateTask'], () => getCCBstatus(CCBid), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const ccbStatus = useMemo<CCBStatus | undefined>(
    () => query.data,
    [query.data]
  );
  // console.log('useGetStatus', ccbStatus);

  return {
    ...query,
    ccbStatus,
  };
};

// export const useFormatedSessionsStatus = (): FormatedCCBSession[] => {
//   const estimatedDurations = useGetEstimatedDurations();
//   const { userOptions } = useAccount();
//   const { currentCCB } = useGetCurrentCCB();
//   // 1 est le CCBid pour récupérer les infos ccb été 2024
//   const { ccbStatus } = useGetStatus(1);

//   const formatedTasks = useMemo(() => {
//     if (ccbStatus && currentCCB) {
//       return (Object.keys(ccbStatus) as Array<keyof typeof ccbStatus>).map(
//         (key) => {
//           const taskStatus = ccbStatus[key];
//           const taskId =
//             typeof taskStatus === 'object' && taskStatus !== null
//               ? taskStatus.task_id
//               : taskStatus;

//           return {
//             ccbId: currentCCB.id,
//             taskId: taskId as number | null,
//             finished:
//               taskStatus === null
//                 ? false
//                 : (taskStatus as { task_id: number; finished: boolean })
//                     .finished,
//             composition_unit: key,
//             start_date: currentCCB.start_date,
//             end_date: currentCCB.end_date,
//             collection: currentCCB.collection,
//             duration: `${estimatedDurations.ucDuration / 60}h`,
//             hasBeenStarted: taskStatus !== null,
//             timeRange: userOptions?.trainingDuration ? 'Oui' : 'Non',
//           };
//         }
//       );
//     }
//     return [];
//   }, [userOptions, currentCCB, ccbStatus, estimatedDurations]);

//   // console.log('useFormatedSessionsStatus', formatedTasks);

//   return formatedTasks;
// };

export const useCCBStatistics = () => {
  const statistics = undefined;
  return { statistics };
};

export const useCCBLcaArticles = (ccbId: number) => {
  const query = useQuery(
    ['ccb_lca_articles', ccbId],
    () => getLCAArticles(ccbId),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
  return query;
};
