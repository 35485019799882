import { useState } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'ui/Modal';
import { device } from 'helpers/css/responsive';
import CCBIntroImage from 'assets/images/CCB-intro.png';
import { ModalHeader } from 'ui/Modal/ModalHeader';
import { AuthorisationCheckbox } from 'ui/AuthorisationCheckbox';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { useNavigate } from 'react-router';
import { AdModal, PopupStatus } from 'http/popup/types';
import { MS_IN_A_DAY } from 'constants/date';
import { persist, MODAL, deserialize } from 'helpers/application/localStorage';

// TODO REFACTO : have modal with localstorage logic with rest as children

export const RedirectionModal = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const shouldDisplayPopup = (): boolean => {
    const modalInCookies = window.localStorage.getItem('MODAL');

    // if modal not in local storage set it and display :
    if (!modalInCookies) {
      persist(MODAL, {
        title: 'pré-CCB',
        status: 'active',
        content: 'want to see again',
        viewed_date: new Date().toISOString(), // Store as ISO string
      });
      return true;
    } else {
      const localStorageModal: AdModal = deserialize(modalInCookies);
      const viewedDate = new Date(localStorageModal.viewed_date); // Convert to Date object
      const modalStatus = localStorageModal.status;

      // if modal is permanent or active display once a day
      if (
        (modalStatus === PopupStatus.ACTIVE ||
          modalStatus === PopupStatus.PERMANENT) &&
        viewedDate
      ) {
        const localStorageDate = viewedDate.getTime();
        const now = new Date().getTime();
        const isOneDayPassed = now - localStorageDate > MS_IN_A_DAY;

        // if a day has passed since last seen update date
        if (isOneDayPassed) {
          persist(MODAL, {
            title: 'pré-CCB',
            status: 'active',
            content: 'want to see again',
            viewed_date: new Date().toISOString(), // Store as ISO string
          });
        }
        return isOneDayPassed;
      } else if (modalStatus === PopupStatus.INACTIVE) {
        return false;
      }
    }
    // if modal status is ACTIVE
    return true;
  };

  const closeModal = () => {
    setIsOpen(false);
    if (checked) {
      persist(MODAL, {
        title: 'pré-CCB',
        status: PopupStatus.INACTIVE,
        content: 'want to see again',
        viewed_date: new Date().toISOString(), // Store as ISO string
      });
    }
  };

  const handleClick = () => {
    if (checked) {
      persist(MODAL, {
        title: 'pré-CCB',
        status: PopupStatus.INACTIVE,
        content: 'want to see again',
        viewed_date: new Date().toISOString(), // Store as ISO string
      });
    }
    navigate('/concours-blanc');
    closeModal();
  };

  if (!shouldDisplayPopup()) return null;

  // console.log('shouldDisplay', shouldDisplayPopup());
  // console.log('no local', window.localStorage);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalHeader onCloseButtonClick={closeModal}>
        <Right>
          <h4>
            👀 Testez-vous gratuitement avec notre prochain concours blanc !
          </h4>
        </Right>
        <Left />
      </ModalHeader>
      <ModalContent>
        <ScrollableContainer>
          <Right>
            <p>
              Nous sommes actuellement en train de préparer le premier concours
              blanc d’asclepia.io qui sera composé sur la même plateforme que
              celle du CNG ! Restez à l'affût pour connaître tous les détails à
              venir.
            </p>
            <AuthorisationCheckbox
              checked={checked}
              text={'Ne plus afficher ce message'}
              type={'newsletterDisplay'}
              onChange={() => setChecked((prev) => !prev)}
            />
            <ButtonContainer>
              <ButtonBase
                onClick={handleClick}
                size="medium"
                styled={'primary'}
                iconOnly={false}
                disabled={false}
                hasPadding={true}
              >
                {'En savoir plus'}
              </ButtonBase>
              <ButtonBase
                onClick={closeModal}
                size="medium"
                styled={'secondary'}
                disabled={false}
                iconOnly={false}
                hasPadding={true}
              >
                {'Fermer'}
              </ButtonBase>
            </ButtonContainer>
          </Right>
          <Left>
            <StyledImage src={CCBIntroImage} alt={'Concours blanc'} />
          </Left>
        </ScrollableContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobile} {
    overflow: auto;
    padding-right: 4px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 60%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const Left = styled.div`
  width: 40%;
  @media ${device.mobile} {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media ${device.mobile} {
    flex-direction: col;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;
