import { useNavigate, useLocation } from 'react-router-dom';

import { routes } from 'routing/constants';

import {
  ContactConfirmationRoutingState,
  isContactConfirmationRoutingState,
} from '../types';

export const useGetContactConfirmationState = ():
  | ContactConfirmationRoutingState
  | undefined => {
  const location = useLocation();
  const navigate = useNavigate();

  if (isContactConfirmationRoutingState(location.state)) {
    return location.state;
  }

  navigate(routes.HOME);
  return undefined;
};
