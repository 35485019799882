import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { useMutation, useQueryClient } from 'react-query';
import { UserOptions } from '../domain/entities/UserOptions';
import { patchUserOptions } from '../domain/useCases/patchUserOptions';
import { USE_GET_USER_OPTIONS_KEY } from './useGetUserOptions';

interface OptimisticUpdateContext<TUpdatedData> {
  previousValue: TUpdatedData | undefined;
}

export const usePatchUserOptions = () => {
  const queryClient = useQueryClient();

  return useMutation<
    UserOptions,
    unknown,
    Partial<UserOptions>,
    OptimisticUpdateContext<UserOptions>
  >(patchUserOptions, {
    onMutate: (modifiedUserOptions) => {
      const previousUserOptions = queryClient.getQueryData<UserOptions>(
        USE_GET_USER_OPTIONS_KEY
      );

      // Optimistic update
      if (previousUserOptions) {
        queryClient.setQueryData<UserOptions>(USE_GET_USER_OPTIONS_KEY, () => {
          const updatedUserOptions: UserOptions = {
            ...previousUserOptions,
            ...modifiedUserOptions,
          };

          return updatedUserOptions;
        });
      }

      return { previousValue: previousUserOptions };
    },
    onSuccess: (updatedUserOptions) => {
      queryClient.setQueryData<UserOptions>(
        USE_GET_USER_OPTIONS_KEY,
        () => updatedUserOptions
      );
    },
    onError: (err, modifiedOptions, context) => {
      // Rollback optimistic update
      queryClient.setQueryData(
        USE_GET_USER_OPTIONS_KEY,
        context?.previousValue
      );
      reportErrorToSentry(err);
    },
  });
};
