import { Menu, MenuHeader, MenuRadioGroup } from '@szhsin/react-menu';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';

import { renderRadios } from 'helpers/ReactMenu/renderRadio';

import { ColumnHeaderWrapper } from '../components/ColumnHeaderWrapper';
import { SortOptions, sortOptions } from '../constants';
import { useTableConfigContext } from '../tableConfigContext';
import { SortColumns } from 'store/types';

export const LastTrainingHeader = () => {
  const dispatch = useDispatch();
  const { tableStateSelector, sortByCreator } = useTableConfigContext();

  const sortOrderDate = useSelector((state: RootState) => {
    const isCurrentSortColumn =
      tableStateSelector(state).sortColumn === 'last_training_date';

    if (isCurrentSortColumn) return tableStateSelector(state).sortOrder;

    return undefined;
  });

  const sortOrderGrade = useSelector((state: RootState) => {
    const isCurrentSortColumn =
      tableStateSelector(state).sortColumn === 'last_grade';

    if (isCurrentSortColumn) return tableStateSelector(state).sortOrder;

    return undefined;
  });

  const setFilterColumn = (sortOrder: SortOptions, sortColumn: SortColumns) => {
    dispatch(sortByCreator({ sortColumn, order: sortOrder }));
  };

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper sortOrder={sortOrderDate || sortOrderGrade}>
          Dernier entraînement
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuHeader>Trier date</MenuHeader>
      <MenuRadioGroup
        value={sortOrderDate}
        onRadioChange={(e) => setFilterColumn(e.value, 'last_training_date')}
      >
        {renderRadios([
          {
            value: sortOptions.ascending,
            label: 'Plus ancien',
          },
          {
            value: sortOptions.descending,
            label: 'Plus récent',
          },
        ])}
      </MenuRadioGroup>

      <MenuHeader>Trier note</MenuHeader>
      <MenuRadioGroup
        value={sortOrderGrade}
        onRadioChange={(e) => setFilterColumn(e.value, 'last_grade')}
      >
        {renderRadios([
          {
            value: sortOptions.ascending,
            label: 'Croissant',
          },
          {
            value: sortOptions.descending,
            label: 'Décroissant',
          },
        ])}
      </MenuRadioGroup>
    </Menu>
  );
};
