import { getAPI } from 'auth';

import { SelectionGroupParams, SelectionGroupResponse } from './types';

export const getSelectionGroup = async (params: SelectionGroupParams) => {
  const response = await getAPI<SelectionGroupResponse>(
    '/selection/filtergroup',
    {
      params,
    }
  );
  return response.data;
};

export const getRandomGroupSample = async (
  table: 'DP' | 'LCA',
  number: number
) => {
  return getSelectionGroup({ type: [table], n_samples: number });
};
