import { FC } from 'react';
import styled from 'styled-components/macro';

import { RadioTexts } from 'ui/RadioTexts/RadioTexts';
import { TextBody2 } from 'ui/TextBody2';
import { Pagination as Paginator } from 'components/Pagination/Pagination';

interface Props {
  numberOfPages?: number;
  currentPage: number;
  setCurrentPage: (val: number) => void;
  pageSize: number;
  setPageSize: (val: number) => void;
}

export const Pagination: FC<Props> = ({
  numberOfPages,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
}) => {
  return (
    <OptionsBottomLine>
      <Paginator
        pageCount={numberOfPages ?? 0}
        forcePage={numberOfPages ? currentPage : -1}
        onPageChange={(p) => setCurrentPage(p.selected)}
      />

      <PageSizeContainer>
        <TextBody2>Nombre d'éléments par page:</TextBody2>
        <RadioTexts
          small
          options={[
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
          ]}
          value={pageSize}
          onChange={setPageSize}
        />
      </PageSizeContainer>
    </OptionsBottomLine>
  );
};

const OptionsBottomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 4px;
`;

const PageSizeContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
`;
