import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { device } from 'helpers/css/responsive';
import { routes } from 'routing/constants';

import { AsclepiaLogo } from 'ui/icons/AsclepiaLogo';

import { useMenuContext } from '../menuContext';
import { ThemeToggler } from 'components/ThemeToggler';
import { Burger } from './components/Burger';
import { AsclepiaLogoWithText } from 'ui/icons/AsclepiaLogoWithText';
import { useSelector } from 'react-redux';
import { selectDevMode } from 'store/slices/appSlice';

export const Header = () => {
  const { toggleMenu } = useMenuContext();
  const isDevMode = useSelector(selectDevMode);

  return (
    <Container>
      <DesktopLeftContainer>
        <ExpandMenuButton onClick={toggleMenu}>
          <Burger />
        </ExpandMenuButton>

        <LogoDesktop to={routes.HOME}>
          <StyledAsclepiaLogoWithText />
        </LogoDesktop>
      </DesktopLeftContainer>
      {isDevMode && (
        <DevMode>
          DEV MODE IS ON <small>(SHIFT+D)</small>
        </DevMode>
      )}

      <LogoMobile to={routes.HOME}>
        <AsclepiaLogo />
      </LogoMobile>

      <ThemeToggler />
    </Container>
  );
};

const DevMode = styled.p`
  color: red;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;

  @media ${device.desktop} {
    font-size: 18px;
  }
`;
const Container = styled.div`
  height: 48px;
  width: 100%;
  padding: 0 16px;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.primary.main};

  @media ${device.desktop} {
    height: 56px;
    padding: 16px 20px;
  }
`;

const ExpandMenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  height: 20px;
  width: 20px;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.primary.dark};

  @media ${device.desktop} {
    height: 24px;
    width: 24px;
  }
`;

const DesktopLeftContainer = styled.div`
  display: flex;
  gap: 72px;
  align-items: center;
`;

const LogoDesktop = styled(Link)`
  display: none;

  @media ${device.desktop} {
    display: block;
    height: 48px;
    width: auto;
  }
`;

const StyledAsclepiaLogoWithText = styled(AsclepiaLogoWithText)`
  height: 48px;
`;

const LogoMobile = styled(Link)`
  display: block;

  height: 40px;
  width: 40px;

  @media ${device.desktop} {
    display: none;
  }
`;
