import React from 'react';
import styled, { useTheme } from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { StatPieChart } from '../GlobalGrades/StatPieChart';
import { Section, SubTitle } from '../CCBStatsAndGrades';

export const RepartitionLca = () => {
  const theme = useTheme();

  return (
    <BlueCard $bigPadding>
      <Section vertical>
        <SubTitle>Répartition de mes réponses de LCA</SubTitle>
        <Legends>
          <Legend>
            <Dot fillColor={theme.colors.success.main} />
            <SubTitleText>Pleinement réussie (1 pt) </SubTitleText>
          </Legend>
          <Legend>
            <Dot fillColor={theme.colors.warning.dark} />
            <SubTitleText>En partie réussie</SubTitleText>
          </Legend>
          <Legend>
            <Dot fillColor={theme.colors.error.dark} />
            <SubTitleText>Non maitrisée (0 pt)</SubTitleText>
          </Legend>
        </Legends>
        <PieSection>
          <StatPieChart />
        </PieSection>
      </Section>
    </BlueCard>
  );
};

const PieSection = styled.div`
  width: 100%;
  aspect-ratio: 1;
`;

export const Legends = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 2rem 0 0;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Legend = styled.div`
  width: 45%;
  display: flex;
  gap: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const SubTitleText = styled.span<{ grey?: boolean }>`
  font-family: 'Urbanist';
  font-weight: 400;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.inactive};
`;

export const Dot = styled.div<{ fillColor: string }>`
  width: 15px;
  height: 15px;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ fillColor }) => fillColor};
`;
