import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { CCBRanking } from './Ranking/CCBRanking';
import { CCBStatsAndGrades } from './Stats/CCBStatsAndGrades';
import { CorrectedCopy } from './Copies/CorrectedCopy';
import { TabSwitch } from './components/TabSwitch';
import { PromotionFilters } from './components/PromotionFilters';
import { device } from 'helpers/css/responsive';

export const PageContent = () => {
  const { statsActiveTab, ccbStatsAvailable } = useSelector(
    (state: any) => state.ccb
  );

  const PageToDisplay = () => {
    switch (statsActiveTab) {
      case 'Classement':
        return <CCBRanking />;
      case 'Correction':
        return <CorrectedCopy />;
      case 'Statistique':
        return <CCBStatsAndGrades />;
      default:
        return <CCBRanking />;
    }
  };

  if (!ccbStatsAvailable)
    return (
      <>Les statistiques de ce concours-blanc ne sont pas encore disponibles</>
    );

  return (
    <PageContentContainer>
      <Headers>
        <TabSwitch />
        <PromotionFilters isShown={statsActiveTab === 'Classement'} />
      </Headers>
      <PageToDisplay />
    </PageContentContainer>
  );
};

const PageContentContainer = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  width: 100%;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
