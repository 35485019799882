import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { FlashPieChart } from './FlashPieChart';

interface Props {}

export const FlashCardsStats: FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <Container>
      <div style={{ position: 'relative' }}>
        <Title>Questions flash</Title>
      </div>
      <MainContainer>
        <FlashPieChart />
        <Legend>
          <LegendT color={theme.colors.flashQuestions.blueLightTranslucent}>
            Nouvelles
          </LegendT>
          <LegendT color={theme.colors.flashQuestions.greenLightTranslucent}>
            À revoir
          </LegendT>
          <LegendT color={theme.colors.flashQuestions.redLightTranslucent}>
            À réviser
          </LegendT>
        </Legend>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 642px;
  height: 729px;
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
`;

const Title = styled.h3``;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Legend = styled.div`
  flex: 2;
  margin-bottom: 0.5em;
`;

const LegendT = styled.div`
  margin: 1em 0em 1em -0.5em;
  font-weight: 600;
  &:before {
    content: '';
    display: inline-block;
    width: 32px;
    height: 12px;
    margin-right: 12px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
  }
`;
