import { GroupType } from 'modules/shared/entities/GroupType';

import { useGetGradesForAKnowledge } from 'http/selection/get_knowledge/connector';
import { CorrectionTaskResponse } from 'types/correction.type';

export interface GradeData {
  grade: number | null;
  totalCoeff: number;
}

export interface DefinedGradeData {
  grade: number;
  totalCoeff: number;
}

export function formatGradePointsCoeff(data: GradeData) {
  if (data.grade === null) {
    return `--/--`;
  }

  return `${data.grade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  })} / ${data.totalCoeff}`;
}

export const ECNI_DENOMINATOR_DP = 420 as const;
export const ECNI_DENOMINATOR_LCA = 540 as const;
export const ECNI_DENOMINATOR_QI = 18 as const;

export function formatGradeECNi(data: GradeData, type: GroupType) {
  if (data.grade === null) {
    return `--/--`;
  }

  const { grade, denominator } = computeGradeECNi(data, type);
  const formattedEcniGrade = grade.toLocaleString('fr-FR', {
    maximumFractionDigits: 0,
  });

  return `${formattedEcniGrade} / ${denominator}`;
}

export const computeGradeECNi = (data: GradeData, type: GroupType) => {
  const denominator =
    type === 'DP'
      ? ECNI_DENOMINATOR_DP
      : type === 'QI'
      ? ECNI_DENOMINATOR_QI * data.totalCoeff
      : ECNI_DENOMINATOR_LCA;

  if (data.grade === null) {
    return { grade: 0, denominator };
  }

  const ecniGrade = (data.grade / data.totalCoeff) * denominator;
  return { grade: ecniGrade, denominator };
};

export function formatGradeOutOfTwenty(data: GradeData) {
  if (data.grade === null) {
    return `--/--`;
  }

  const denominator = 20;

  const twentyGrade = (data.grade / data.totalCoeff) * denominator;
  const formattedTwentyGrade = twentyGrade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${formattedTwentyGrade} / ${denominator}`;
}

export function formatGradeForARankOutOfTwenty(data: GradeData[] | undefined) {
  if (!data) {
    return `--/--`;
  }

  const gradeForARankOnTwenty = data?.reduce(
    (accumulator, currentValue) => {
      if (currentValue && currentValue.grade) {
        accumulator.grade += currentValue.grade;
      }
      if (accumulator && currentValue.totalCoeff) {
        accumulator.denominator += currentValue.totalCoeff;
      }
      return accumulator;
    },
    { grade: 0, denominator: 0 }
  );

  const denominatorOnTwenty = 20;
  const denominator =
    gradeForARankOnTwenty.denominator > 0
      ? gradeForARankOnTwenty.denominator
      : 1;

  const averageGrade =
    (gradeForARankOnTwenty.grade / denominator) * denominatorOnTwenty;

  const formattedTwentyGrade = averageGrade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${formattedTwentyGrade} / ${denominatorOnTwenty}`;
}

export function formatGradeForARank(data: GradeData[] | undefined) {
  if (!data) {
    return `--/--`;
  }

  const gradeForARank = data?.reduce(
    (accumulator, currentValue) => {
      if (currentValue && currentValue.grade) {
        accumulator.grade += currentValue.grade;
      }
      if (accumulator && currentValue.totalCoeff) {
        accumulator.denominator += currentValue.totalCoeff;
      }
      return accumulator;
    },
    { grade: 0, denominator: 0 }
  );

  const denominator =
    gradeForARank.denominator > 0 ? gradeForARank.denominator : 1;

  const formattedTwentyGrade = gradeForARank.grade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${formattedTwentyGrade} / ${denominator}`;
}

export function isMoreThanFourteenOutOfTwenty(data: string) {
  if (data === '') {
    return false;
  }

  const parts = data.split('/');
  const gradePart = parts[0].trim();
  const gradeNumber = parseInt(gradePart, 10);

  if (isNaN(gradeNumber)) {
    return null;
  }

  return gradeNumber > 14;
}

export function computeGradeOnTraining(data: CorrectionTaskResponse) {
  if (!data) {
    return '--/--';
  }
  const gradeInPoints = data.correction_groups.reduce(
    (accumulator: { grade: number; denominator: number }, currentValue) => {
      const coefficient = currentValue.total_coefficient ?? 1;
      const grade = currentValue.grade ?? 0;
      accumulator.grade += grade;
      accumulator.denominator += coefficient;
      return accumulator;
    },
    { grade: 0, denominator: 0 }
  );
  const trainingrade = gradeInPoints.grade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${trainingrade} / ${gradeInPoints.denominator}`;
}

export function computeGradeOnTrainingOnTwenty(data: CorrectionTaskResponse) {
  if (!data || !data.correction_groups) {
    return '--/--';
  }
  const gradeInPoints = data.correction_groups.reduce(
    (accumulator: { grade: number; denominator: number }, currentValue) => {
      const coefficient = currentValue.total_coefficient ?? 1;
      const grade = currentValue.grade ?? 0;
      accumulator.grade += grade;
      accumulator.denominator += coefficient;
      return accumulator;
    },
    { grade: 0, denominator: 0 }
  );

  const denominator = 20;
  const twentyGrade =
    (gradeInPoints.grade / gradeInPoints.denominator) * denominator;

  const trainingradeInPoints = twentyGrade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${trainingradeInPoints} / ${denominator}`;
}

export function computeGradeForARankOnTraining(data: CorrectionTaskResponse) {
  if (!data || !data.correction_groups) {
    return '--/--';
  }

  const gradeForARankOnTraining = data.correction_groups.reduce(
    (accumulator, currentValue) => {
      const { aRankGradeData, isThereARankQuestion } =
        useGetGradesForAKnowledge(currentValue);
      if (isThereARankQuestion) {
        accumulator.isThereARank = true;
        const gradeForARank: string[] =
          formatGradeForARank(aRankGradeData).split('/');
        accumulator.grade += parseInt(gradeForARank[0].trim(), 10);
        accumulator.coeff += parseInt(gradeForARank[1].trim(), 10);
      }
      return accumulator;
    },
    { grade: 0, coeff: 0, isThereARank: false }
  );

  if (gradeForARankOnTraining.isThereARank === false) {
    return '--/--';
  }

  const trainingGradeARank = gradeForARankOnTraining.grade.toLocaleString(
    'fr-FR',
    {
      maximumFractionDigits: 2,
    }
  );

  return `${trainingGradeARank} / ${gradeForARankOnTraining.coeff}`;
}

export function computeGradeForARankOnTrainingOnTwenty(
  data: CorrectionTaskResponse
) {
  if (!data || !data.correction_groups) {
    return '--/--';
  }
  const gradeForARankOnTraining = data.correction_groups.reduce(
    (accumulator, currentValue) => {
      const { aRankGradeData, isThereARankQuestion } =
        useGetGradesForAKnowledge(currentValue);
      if (isThereARankQuestion) {
        accumulator.isThereARank = true;
        const gradeForARank: string[] =
          formatGradeForARank(aRankGradeData).split('/');
        accumulator.grade += parseInt(gradeForARank[0].trim(), 10);
        accumulator.coeff += parseInt(gradeForARank[1].trim(), 10);
      }
      return accumulator;
    },
    { grade: 0, coeff: 0, isThereARank: false }
  );

  if (gradeForARankOnTraining.isThereARank === false) {
    return '--/--';
  }

  const denominator = 20;
  const twentyGrade =
    (gradeForARankOnTraining.grade / gradeForARankOnTraining.coeff) *
    denominator;

  const trainingGradeARankOnTwenty = twentyGrade.toLocaleString('fr-FR', {
    maximumFractionDigits: 2,
  });

  return `${trainingGradeARankOnTwenty} / ${denominator}`;
}

// TEST
// export function computeGradeForARankOnTrainingOnTwenty(
//   data: CorrectionTaskResponse
// ) {
//   if (!data || !data.correction_groups) {
//     return '--/--';
//   }

//   let totalGrade = 0;
//   let totalCoefficient = 0;

//   data.correction_groups.forEach((group) => {
//     const { aRankGradeData, isThereARankQuestion } = useGetGradesForAKnowledge(group);
//     if (isThereARankQuestion) {
//       const [grade, coefficient] = formatGradeForARank(aRankGradeData).split('/').map(str => parseInt(str.trim(), 10));
//       totalGrade += grade * coefficient;
//       totalCoefficient += coefficient;
//     }
//   });

//   if (totalCoefficient === 0) {
//     return '--/--';
//   }

//   const denominator = 20;
//   const twentyGrade = (totalGrade / totalCoefficient) * denominator;

//   const trainingGradeARankOnTwenty = twentyGrade.toLocaleString('fr-FR', {
//     maximumFractionDigits: 2,
//   });

//   return `${trainingGradeARankOnTwenty} / ${denominator}`;
// }
