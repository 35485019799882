import { FC } from 'react';
import { Menu, MenuHeader, MenuRadioGroup } from '@szhsin/react-menu';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';

import { renderRadios } from 'helpers/ReactMenu/renderRadio';

import { ColumnHeaderWrapper } from '../components/ColumnHeaderWrapper';
import { SortOptions, sortOptions } from '../constants';
import { useTableConfigContext } from '../tableConfigContext';
import { SortColumns } from 'store/types';

interface Props {
  columnName: string;
  sortColumn: SortColumns;
  isSub?: boolean;
  ascendingOptionText?: string;
  descendingOptionText?: string;
  disabled?: boolean;
}

export const GenericSortHeader: FC<Props> = ({
  sortColumn,
  columnName,
  isSub,
  ascendingOptionText,
  descendingOptionText,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { tableStateSelector, sortByCreator } = useTableConfigContext();

  const sortOrder = useSelector((state: RootState) => {
    const isCurrentSortColumn =
      tableStateSelector(state).sortColumn === sortColumn;

    if (isCurrentSortColumn) return tableStateSelector(state).sortOrder;

    return undefined;
  });

  const setFilterColumn = (sortOrder: SortOptions) => {
    dispatch(sortByCreator({ sortColumn, order: sortOrder }));
  };

  if (disabled) {
    return (
      <ColumnHeaderWrapper isSub={isSub} isReadOnly>
        {columnName}
      </ColumnHeaderWrapper>
    );
  }

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isSub={isSub} sortOrder={sortOrder}>
          {columnName}
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuHeader>Trier</MenuHeader>
      <MenuRadioGroup
        value={sortOrder}
        onRadioChange={(e) => setFilterColumn(e.value)}
      >
        {renderRadios([
          {
            value: sortOptions.ascending,
            label: ascendingOptionText ?? 'Croissant',
          },
          {
            value: sortOptions.descending,
            label: descendingOptionText ?? 'Décroissant',
          },
        ])}
      </MenuRadioGroup>
    </Menu>
  );
};
