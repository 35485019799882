import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';

export const BlueCard = styled.div<{
  $smallPading?: boolean;
  $bigPadding?: boolean;
  color?: string;
  hasBorder?: boolean;
  hasMargin?: boolean;
  gap?: string;
  fullWidth?: boolean;
  maxWidth?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: ${({ hasMargin }) =>
    hasMargin ? '8px var(--horizontal-padding) 0' : 'none'};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: ${({ $smallPading, $bigPadding }) =>
    $smallPading ? ' 0.85rem' : $bigPadding ? '2rem' : '1rem'};
  ${({ theme, hasBorder }) => (hasBorder ? theme.colors.inactive : 'none')};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.dark};
  overflow: hidden;
  gap: ${({ gap }) => gap};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  img {
    max-width: 100%;
    height: auto;
  }
  @media ${device.mobile} {
    max-width: 100%;
  }
`;

export default BlueCard;
