import {
  Menu,
  MenuHeader,
  MenuRadioGroup,
  MenuDivider,
  SubMenu,
  FocusableItem,
  MenuItem,
} from '@szhsin/react-menu';
import { renderRadios } from 'helpers/ReactMenu/renderRadio';
import { ColumnHeaderWrapper } from 'pages/selection/components/ColumnHeaderWrapper';
import { FilterInput } from 'pages/selection/components/FilterInput';
import { SortOptions, sortOptions } from 'pages/selection/constants';
import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  searchInput: string;
  setSearchInput: (value: string) => void;
  filteredOptions: { value: string; label: string }[];
  toggleFilter: (value: string) => void;
  isFilterSelected: (value: string) => boolean;
  isFiltering: boolean;
  sortOrder: SortOptions;
  setSortOrder: (value: SortOptions) => void;
}

const FilterLayout: React.FC<Props> = ({
  title,
  searchInput,
  setSearchInput,
  filteredOptions,
  toggleFilter,
  isFilterSelected,
  isFiltering,
  sortOrder,
  setSortOrder,
}) => {
  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isFiltering={isFiltering} sortOrder={sortOrder}>
          <h2>{title}</h2>
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuHeader>Trier</MenuHeader>
      <MenuRadioGroup
        value={sortOrder}
        onRadioChange={(e) => {
          setSortOrder(e.value);
        }}
      >
        {renderRadios([
          { value: sortOptions.ascending, label: 'Croissant' },
          { value: sortOptions.descending, label: 'Décroissant' },
        ])}
      </MenuRadioGroup>
      <MenuDivider />
      <MenuHeader>Filtre</MenuHeader>
      <SubMenu
        label="Filtrer par"
        menuStyles={{ maxHeight: 500, overflow: 'auto', paddingTop: 0 }}
      >
        <StickyFocusableItem>
          {({ ref }) => (
            <FullWidthInput
              ref={ref}
              type="text"
              placeholder="Filtrer"
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
            />
          )}
        </StickyFocusableItem>
        {filteredOptions.slice(0, 100).map((option) => (
          <MenuItemStyled
            type="checkbox"
            value={option.value}
            key={option.value}
            checked={isFilterSelected(option.value)}
            onClick={(e) => {
              e.keepOpen = true;
              toggleFilter(option.value);
            }}
          >
            {option.label}
          </MenuItemStyled>
        ))}
      </SubMenu>
    </Menu>
  );
};

const StickyFocusableItem = styled(FocusableItem)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const FullWidthInput = styled(FilterInput)`
  width: 100%;
`;

const MenuItemStyled = styled(MenuItem)`
  max-width: 600px;
`;

export default FilterLayout;
