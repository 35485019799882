import { FC } from 'react';
import styled from 'styled-components/macro';
import { Book } from './Book';
import { BookType } from 'types/book.type';

export interface BooksProps {
  books: BookType[] | undefined;
  isBlue: boolean;
}

export const Books: FC<BooksProps> = ({ books, isBlue }) => {
  if (!books || books.length === 0) return null;

  return (
    <BooksContainer>
      {books?.map((book: BookType) => (
        <Book key={book.id} book={book} isBlue={isBlue} />
      ))}
    </BooksContainer>
  );
};

const BooksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 40%;
`;
