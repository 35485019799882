import { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { SmallDownArrow } from 'ui/icons/SmallDownArrow';
import { device } from 'helpers/css/responsive';

export const CollapsableContainer: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  return (
    <Container>
      <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed ? <ArrowToLeft /> : <ArrowToRight />}
      </CollapseButton>
      <Collapsable isCollapsed={isCollapsed}>{children}</Collapsable>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
`;

const Collapsable = styled.div<{ isCollapsed: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;

  width: ${({ isCollapsed }) => (isCollapsed ? '0' : 'auto')};
  height: ${({ isCollapsed }) => (isCollapsed ? '0' : '100%')};

  @media ${device.largeTable} {
    width: auto;
    height: 100%;
  }
`;

const CollapseButton = styled.button`
  all: unset;
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 12px;
  display: block;

  @media ${device.largeTable} {
    display: none;
  }
`;

const ArrowToRight = styled(SmallDownArrow)`
  transform: rotate(-90deg);
`;

const ArrowToLeft = styled(SmallDownArrow)`
  transform: rotate(90deg);
`;
