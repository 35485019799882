import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { MyGradeCard } from './GradeCard';
import { updateStatsActiveTab } from 'store/slices/ccbSlice';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { ArrowRight } from 'ui/icons/Arrows/Arrow';

interface Props {
  title?: string;
  link?: boolean;
}

export const MyGrades: React.FC<Props> = ({ title, link }) => {
  const { ccbRanking } = useSelector((state: any) => state.ccb);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateStatsActiveTab('Correction'));
  };
  return (
    <Container>
      {title && <h3>{title}</h3>}
      <CardContainer>
        <MyGradeCard
          fullWidth
          ranks={['A']}
          grade={ccbRanking?.user_rank_A_grade ?? 0}
          text={'Note de rang A validant'}
        />
        <MyGradeCard
          fullWidth
          ranks={['A', 'B']}
          grade={ccbRanking?.user_global_grade}
          text={'Note globale avec coefficient 1 pour le rang B'}
        />
      </CardContainer>
      {link && (
        <ButtonBase
          styled={'link-red'}
          size={'medium'}
          onClick={handleClick}
          disabled={false}
          hasPadding={false}
        >
          Voir plus de notes <ArrowRight />
        </ButtonBase>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;
