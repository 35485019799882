export const routes = {
  HOME: '/',
  LOGIN: '/login',
  LOST_PASSWORD: '/lost-password',
  SIGN_UP: '/sign-up',
  PROGRESS: '/progress',
  SELECTION: '/selection',
  FLASH_QUESTIONS: '/flash-questions',
  START: '/start',
  LESSONS: '/lessons',
  RESSOURCES: '/ressources',
  ACCOUNT: '/account',
  // CONTACT: '/contact',
  CONTACT_CONFIRMATION: '/contact-confirmation',
  AUTH_CONFIRMATION: '/auth-confirmation',
  VERIFY_EMAIL: '/verify-email/:token',
  CHANGE_PASSWORD: '/change-password/:token',
  CHANGE_PASSWORD_CONNECTED_USER: '/change-password-connected',
  CHANGE_EMAIL: '/change-email',
  LOGOUT: '/logout',
  DELETE_ACCOUNT: '/delete-account',
  TRAINING: '/training',
  CCBTRAINING: '/ccbtraining',
  CORRECTION: '/correction',
  PAYMENT_SUCCESS: '/payment/success',
  PAYMENT_FAILURE: '/payment/failure',
  SUBSCRIBE: '/subscribe',
  TASK_CORRECTION: '/correction/task',
  GROUP_CORRECTION: '/correction/group',
  LAST_CORRECTION: '/correction',
  COMPASS: '/astrolabe',
  CONCOURS_BLANC: '/concours-blanc',
  EASY_DISPO: '/easy-dispo',
  FAQ: '/faq',
} as const;
